import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClass,
  selectClassById,
  selectClassesError,
  selectClassesStatus,
  updateClass,
} from "./slice";
import { useLocation } from "react-router-dom";
import Alert from "../../components/common/alert";

const UpdateClassModal = ({ show, handleClose, classId }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [inputData, setInputData] = useState({});
  const [sessionInputs, setSessionInputs] = useState([
    {
      day: "",
      start_at: "",
      end_at: "",
    },
  ]);

  const {
    state: { id },
  } = useLocation();

  const dispatch = useDispatch();

  const status = useSelector(selectClassesStatus);
  const error = useSelector(selectClassesError);

  const classItem = useSelector(selectClassById(id));

  //  const courseStatus = useSelector(selectCourseStatus);
  //  const courses = useSelector(selectCourses);

  const addSessionInput = (idx) => {
    if (idx >= 0) {
      const newArr = sessionInputs.filter((el, indx) => indx !== idx);
      setSessionInputs(newArr);
    } else {
      setSessionInputs((arr) => {
        return [
          ...arr,
          {
            day: "",
            start_at: "",
            end_at: "",
          },
        ];
      });
    }
  };

  const handleSessionInput = (id) => (e) => {
    const { name, value } = e.target;

    const sessionValues = sessionInputs.map((el, idx) =>
      idx === id ? { ...el, [name]: value } : el
    );
    // console.log("sessionInputs", sessionInputs);
    setInputData({ ...inputData, sessions: sessionValues });
    setSessionInputs(sessionValues);
  };
  //  const handleCourseInput = (e) => {
  //    // setCourseId(e.target.value);
  //    const course = courses.find(
  //      (course) => course.id === parseInt(e.target.value)
  //    );
  //    setClassItem({ ...classItem, course_id: course.id });
  //  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...inputData,
        id: classId,
      };
      await dispatch(updateClass(payload)).unwrap();
    } catch (err) {
      console.log("Error,", err);
      //  const { msg, success } = err;
      //  setAlertMsg({
      //    ...alertMsg,
      //    msg: msg,
      //  });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Class Updated Successfully",
      });
      setShowAlert(true);
      //  e.target.reset();
    }
  };

  useEffect(() => {
    if (!classItem) dispatch(fetchClass({ classId: id }));
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 5000);
  }, [classItem, dispatch, id, showAlert]);

  return (
    <>
      {show && (
        <div
          className="fixed inset-0 z-10 w-screen overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          {/* Background overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
            aria-hidden="true"
          ></div>

          {/* Modal container */}
          <div className="flex min-h-screen items-center justify-center p-4 sm:p-6">
            <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full sm:max-w-sm md:max-w-md lg:max-w-lg">
              <div className="px-4 py-5 sm:p-6">
                {/* Modal header */}
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-lg sm:text-xl font-semibold text-gray-900">
                    Update Class
                  </h1>
                  <button
                    type="button"
                    className="p-2 rounded-full text-red-500 hover:bg-red-100"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <div className="flex justify-center col-span-full">
                  {showAlert && (
                    <Alert
                      show={showAlert}
                      content={error}
                      type={status}
                    ></Alert>
                  )}
                </div>
                {/* Form */}
                <form className="space-y-4" onSubmit={handleSubmit}>
                  {/* Input fields */}
                  <div className="col-span-full">
                    <label
                      htmlFor="course_code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Class Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="class_name"
                        id="class_name"
                        defaultValue={classItem.class_name}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  {/* Session Fields */}
                  <fieldset>
                    <legend className="block text-sm font-medium text-gray-700">
                      Class Sessions
                    </legend>
                    {sessionInputs.map((item, index) => (
                      <div
                        className="flex flex-wrap justify-between mb-4 gap-2"
                        key={index}
                      >
                        <div className="w-full sm:w-auto flex-1">
                          <label
                            htmlFor="session-day"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Day
                          </label>
                          <select
                            id="session-day"
                            name="day"
                            value={item.day || ""}
                            onChange={(e) => handleSessionInput(index)(e)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          >
                            <option>Select Day</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            <option value="Chapel">Sunday Chapel</option>
                          </select>
                        </div>

                        <div className="w-full sm:w-auto flex-1">
                          <label
                            htmlFor="session-start"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Start At:
                          </label>
                          <input
                            id="session-start"
                            name="start_at"
                            type="time"
                            min="07:00"
                            max="18:00"
                            onChange={(e) => handleSessionInput(index)(e)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>

                        <div className="w-full sm:w-auto flex-1">
                          <label
                            htmlFor="session-end"
                            className="block text-sm font-medium text-gray-700"
                          >
                            End At:
                          </label>
                          <input
                            id="session-end"
                            name="end_at"
                            type="time"
                            min="07:00"
                            max="18:00"
                            onChange={(e) => handleSessionInput(index)(e)}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          ></input>
                        </div>
                        <button
                          type="button"
                          className="p-2 rounded-full text-red-600 hover:bg-red-100"
                          onClick={() => addSessionInput(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addSessionInput()}
                      className="w-full sm:w-auto inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700"
                    >
                      + Add Session
                    </button>
                  </fieldset>

                  {/* Action buttons */}
                  <div className="mt-3 flex justify-end space-x-3">
                    <button
                      onClick={handleClose}
                      className="px-4 py-2 text-sm font-medium text-blue-600 rounded-md hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md hover:bg-blue-700"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateClassModal;
