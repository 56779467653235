import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../components/common/alert";
import {
  pay,
  selectEnrollError,
  verifyEnrollment,
} from "../../features/enrollment/enrollSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { EnrollCard } from "./register";
import { getStudent } from "../../features/registrations/registrationSlice";

export const MpesaExpress = () => {
  const [showLoading, setShowLoading] = useState(true);
  const [verificationInfo, setVerificationInfo] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [enrollInfo, setEnrollInfo] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [requestStatus, setRequestStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const err = useSelector(selectEnrollError);
  const {
    state: { enrollment_id, student_id },
  } = useLocation();

  const handlePhoneInput = (e) => {
    setPhoneNo(e.target.value);
    setPaymentDetails({
      ...paymentDetails,
      enrollment_id: enrollment_id,
      amount: 1,
      student_id: enrollInfo.student_id,
      payment_method: "express",
      paying_msisdn: e.target.value,
    });
  };

  const handleCompleteEnrollment = async (e) => {
    try {
      // console.log("VerificationInfo::", verificationInfo);
      if (Object.keys(verificationInfo || {}).length !== 0) {
        await dispatch(verifyEnrollment(verificationInfo))
          .unwrap()
          .then((results) => {
            console.log("Pay results", results);
            const { data, msg, status } = results;
            if (status !== 200) {
              navigate("/enrollment-successful", {
                state: { status: "failed", msg: msg },
              });
            } else {
              navigate("/enrollment-successful", {
                state: { status: "success", data: data, msg: msg },
              });
            }
          });
      }
    } catch (error) {}
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("paymentDetails", paymentDetails);
    try {
      await dispatch(pay(paymentDetails))
        .unwrap()
        .then((results) => {
          console.log("Payment Response", results);
          const { data, msg, status } = results;
          if (status !== 200) {
            setErrorMsg(msg);
            setShowAlert(true);
          } else {
            setVerificationInfo({
              payment_request_id: data.payment_request_id,
              student_msisdn: data.student_msisdn,
            });
            setRequestStatus(true);
          }
        });
    } catch (error) {
      console.log("error: ", error);
      setErrorMsg(err);
      setShowAlert(true);
    } finally {
      setPaymentDetails({});
      e.target.reset();
    }
  };

  const getEnrollment = useCallback(async () => {
    // await dispatch(fetchEnrollments({ params: { id: enrollment_id } }))
    await dispatch(getStudent({ params: { q: student_id } }))
      .unwrap()
      .then((results) => {
        console.log("res", results);
        setEnrollInfo(results);
        setShowLoading(false);
      });
  }, [dispatch, student_id]);

  useEffect(() => {
    console.log("I fired!");
    if (Object.keys(enrollInfo || {}).length === 0) {
      getEnrollment();
    }
    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 6000);
  }, [enrollInfo, getEnrollment, showAlert]);
  return (
    <>
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
        <div className="px-4 py-6 sm:px-6 lg:hidden">
          <div className="mx-auto flex max-w-lg">
            <a href="/">
              <span className="sr-only">IYF Free Weekend Academy</span>
              <img
                src="../assets/png/iyflogo-s7.png"
                alt=""
                className="h-12 w-auto"
              />
            </a>
          </div>
        </div>

        <h1 className="sr-only">IYF Free Academy - Registration</h1>

        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <div className="hidden pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                />
              </a>
            </div>
            {showLoading ? (
              <div className="mx-auto max-w-lg">
                <div className="relative items-center block max-w-sm p-6 bg-white border border-gray-100 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-800 dark:hover:bg-gray-700">
                  <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                    Processing ...
                  </h5>
                  <div
                    role="status"
                    className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Processing your request ...</span>
                  </div>
                </div>
              </div>
            ) : enrollInfo.enroll_status === "pending" ? (
              <form className="mt-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-full">
                    <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                      <h1 className="text-2xl font-bold">
                        Hey {enrollInfo.full_name}, Pay Kshs 500
                      </h1>
                      <p>
                        Pay Kshs 500 to Enroll for{" "}
                        {enrollInfo.enrollments[0]?.class.class}
                        {/* Pay Kshs 500 to Enroll for {enrollInfo.class.class} */}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-full">
                    <div className="col-span-full">
                      {showAlert && (
                        <Alert
                          show={showAlert}
                          content={errorMsg}
                          type="failed"
                        ></Alert>
                      )}
                    </div>

                    {requestStatus ? (
                      <div>
                        <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <label className="block text font-bold text-gray-700">
                            Mpesa Express
                          </label>
                          <p className="block text-sm font-medium text-gray-700 mb-2">
                            Send an M-Pesa payment request to your phone number
                          </p>

                          <div className="rounded-md bg-green-50 p-4 mb-2">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <svg
                                  className="h-5 w-5 text-green-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-green-800">
                                  An MPESA Payment request has been sent to your
                                  phone successfully. Check and enter your
                                  M-PESA PIN.
                                </h3>
                              </div>
                            </div>
                          </div>

                          <div className="mt-1 flex flex-col space-y-2 sm:flex-row sm:justify-between sm:items-center sm:space-y-0 sm:space-x-2">
                            <div className="relative mt-2 rounded-md w-full">
                              <input
                                type="text"
                                id="phone-number"
                                name="phone-number"
                                autoComplete="Phone"
                                value={phoneNo}
                                disabled
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:bg-gray-200 disabled:cursor-not-allowed"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-5 h-5 me-3 text-gray-200 animate-spin dark:text-gray-400"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#1C64F2"
                                  />
                                </svg>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="hidden w-full sm:w-auto rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-nowrap"
                            >
                              Try Again
                            </button>
                          </div>
                        </div>
                        <div className="mt-5">
                          <button
                            className="text-sm mr-4 font-medium text-green-600"
                            type="button"
                            onClick={handleCompleteEnrollment}
                          >
                            Complete
                          </button>
                          {/* <button
                            className="text-sm font-medium text-red-600"
                            onClick={() => {
                              setRequestStatus(false);
                            }}
                          >
                            Payment Timeout
                          </button> */}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                          <label className="block text font-bold text-gray-700">
                            Mpesa Express
                          </label>
                          <p className="block text-sm font-medium text-gray-700 mb-2">
                            Send an M-Pesa payment request to your phone number
                          </p>
                          {/* <div className="mt-1">
                          <input
                            id="amount"
                            name="amount"
                            type="disabled"
                            // onChange={handleEnrollmentInput}
                            value={1.0}
                            className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                          ></input>
                        </div> */}
                          <div className="mt-1 flex flex-col space-y-2 sm:flex-row sm:justify-between sm:items-center sm:space-y-0 sm:space-x-2">
                            <input
                              type="text"
                              id="phone-number"
                              name="paying_msisdn"
                              autoComplete="Phone"
                              // value="+254724348779"
                              onChange={handlePhoneInput}
                              placeholder="eg. 0700 123 456"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                            <button
                              type="submit"
                              className="w-full sm:w-auto rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-nowrap"
                            >
                              Send Payment Request
                            </button>
                          </div>
                        </div>{" "}
                        <div className="flex justify-between">
                          <Link
                            to="/"
                            className="mt-6 rounded-md text-sm text-gray-500 px-4 py-2 hover:bg-white"
                          >
                            Cancel
                          </Link>
                          <Link
                            to="/mpesa-classic"
                            state={{ enrollment_id: enrollment_id, student_id: student_id }}
                            className="mt-6 rounded-md text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                          >
                            Use M-Pesa Classic (PayBill No.)
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                  <svg
                    className="mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  We adhere to the highest standards of data security in all
                  processing and storage of your information.
                </p>
              </form>
            ) : (
              <EnrollCard student={enrollInfo} />
            )}
          </div>
        </section>
      </main>
    </>
  );
};
