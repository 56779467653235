import React from "react";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 lg:px-6 mb-20">
        <div className="flex flex-col items-center justify-center mx-auto max-w-2xl lg:max-w-none">
          <div className="flex items-center justify-center mx-auto mb-8 mt-8 lg:mt-8">
            <a href="/">
              <span className="sr-only">IYF Free Weekend Academy</span>
              <img
                src="../assets/png/iyflogo-s7.png"
                alt=""
                className="h-12 lg:h-16 w-auto"
              ></img>
            </a>
          </div>

          <dl className="grid grid-cols-1 gap-8 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-2 max-w-2xl">
            <div className="flex flex-col bg-white p-4 shadow-sm border border-gray-200 rounded-xl">
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center justify-center w-full pt-2">
                  <img
                    src="../assets/png/register.png"
                    alt=""
                    className="h-24"
                  ></img>
                </div>
                <div className="mt-4">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href="/">Register for Academy</a>
                  </h3>
                  {/* <p className="mt-3 text-sm leading-6 text-gray-600">
                    If you're new to our weekend academy, please fill out the
                    Registration form with your basic information.
                  </p> */}

                  <p className="mt-3 text-normal p-1.5 leading-6 text-rose-900 rounded-xl">
                    Season 7 registration is closed.
                  </p>
                  {/* <div className="mt-3 text-lg p-3 leading-6 text-white bg-emerald-600 rounded-xl">
                    <p className="p-3 text-lg font-semibold leading-6 text-white">
                      Register for New Season 8{" "}
                    </p>
                    <span>
                      <a
                        href="https://www.freeacademy.iyfkenya.org"
                        className="bg-gray-600 py-1.5 px-5 text-sm text-white rounded-md hover:bg-emerald-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        target="_blank" rel="noreferrer"
                      >
                        Here
                      </a>
                    </span>
                  </div> */}
                  <p className="mt-3 text-normal p-5 leading-6 text-white bg-blue-600 rounded-xl">
                    However, If you already registered for season 7 but have not
                    enrolled, proceed to enroll for a course of your choice.{" "}
                    <Link
                      to="/enroll"
                      className="rounded-md border border-emerald-600 bg-orange-400 p-1 text-sm text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      // style={{ cursor: "default" }}
                      // className="mt-6 rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                    >
                      Here
                    </Link>
                  </p>
                  {/* <Link
                    to="/register"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                  >
                    Register
                  </Link> */}
                  {/* <button
                    className="mt-6 rounded-md border border-transparent bg-rose-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                    disabled={true}
                  >
                    Registration Closed.
                  </button> */}
                </div>
              </article>
            </div>
            <div className="flex flex-col bg-white p-4 shadow-sm border border-gray-200 rounded-xl">
              <article className="flex max-w-xl flex-col items-start justify-between">
                <div className="flex items-center justify-center w-full pt-2">
                  <img
                    src="../assets/png/enroll.png"
                    alt=""
                    className="h-24"
                  ></img>
                </div>
                <div className="mt-4">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href="/">Enroll and Confirm</a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-600">
                    Select the class session for Saturday or Sunday to enroll.
                    If you’ve already enrolled, you can confirm your
                    information.
                  </p>
                  <Link
                    to="/enroll"
                    className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                    // style={{ cursor: "default" }}
                    // className="mt-6 rounded-md border border-transparent bg-gray-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 block"
                  >
                    Enroll
                  </Link>
                </div>
              </article>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};
