import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchAttendanceRegister = createAsyncThunk(
  "attendance/fetchAttendanceRegister",
  async (params, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.fetchAttendance(params);
      const { msg, data } = response;
      return { data, msg };
    } catch (error) {
      const { data: response, status } = error.response;
      return rejectWithValue({ msg: response.msg, status });
    }
  }
);

export const addRegisterAsync = createAsyncThunk(
  "attendance/addRegister",
  async (register, { rejectWithValue }) => {
    try {
      const { status, data: response } = await Apis.createRegister(register);
      return status === 201
        ? { data: response.data, msg: response.msg }
        : rejectWithValue({ msg: response.msg });
    } catch (error) {
      const { data: response } = error.response;
      return rejectWithValue({ msg: response.msg, status: 500 });
    }
  }
);

export const updateRegister = createAsyncThunk(
  "attendance/updateRegister",
  async (register, { rejectWithValue }) => {
    try {
      const {
        status,
        data: { data, msg },
      } = await Apis.createRegister(register);
      return status === 200
        ? { data, msg }
        : rejectWithValue({ msg: data.msg });
    } catch (error) {
      const { data: response } = error.response;
      return rejectWithValue({ msg: response.msg, status: 500 });
    }
  }
);

const initialState = {
  status: "idle",
  error: null,
  attendance: null,
};

const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendanceRegister.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.attendance = data;
        state.error = msg;
      })
      .addCase(addRegisterAsync.fulfilled, (state, action) => {
        const { msg } = action.payload;
        state.status = "success";
        state.error = msg;
      })
      .addCase(updateRegister.fulfilled, (state, action) => {
        const { msg } = action.payload;
        state.status = "success";
        state.error = msg;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "pending";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = "Request Failed";
        }
      );
  },
});

export const selectStatus = (state) => state.attendance.status;
export const selectError = (state) => state.attendance.error;
export const selectAttendance = createSelector(
  (state) => state.attendance,
  (attendanceState) => attendanceState.attendance
);

export default attendanceSlice.reducer;
