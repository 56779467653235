import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isRejected,
  isPending,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

// Thunk for fetching all classes
export const fetchClasses = createAsyncThunk(
  "classes/fetchAll",
  async ({ page, pageSize, search }, { getState, rejectWithValue }) => {
    try {
      // check stored cache
      const { classes } = getState();
      const cachedPage = classes.pages[`${page}-${search}`];
      if (cachedPage) {
        return {
          data: cachedPage,
          total: classes.total,
          page,
          search,
        };
      }
      const {
        data: {
          data: { classes: data, total },
        },
      } = await Apis.getAllClasses({ params: { page, pageSize, search } });
      return { data, total, page, search };
    } catch (error) {
      // Reject with the error message
      //  console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      return rejectWithValue(msg || "Failed to fetch classes");
    }
  }
);

// Thunk for fetching a single class by ID
export const fetchClass = createAsyncThunk(
  "classes/fetchClass",
  async (param, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.getClass(param);
      return { data, msg };
    } catch (error) {
      // console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to fetch class with ID: ${param}`);
    }
  }
);

// Thunk for creating a new class
export const createClass = createAsyncThunk(
  "classes/create",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.addClass(payload);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to add class. Try again later`);
    }
  }
);

// Thunk for updating an existing class
export const updateClass = createAsyncThunk(
  "classes/update",
  async (classData, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.updateClass(classData);
      return { data, msg, success };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Updating class failed. Try again later`);
    }
  }
);

// Thunk for deleting a class
export const deleteClass = createAsyncThunk(
  "classes/delete",
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.removeClass(params);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("DeleteClass;Error", msg);
      return rejectWithValue(
        msg || `Failed to delete class with ID: ${params.id}`
      );
    }
  }
);

const initialState = {
  allIDs: [],
  classById: {},
  status: "idle",
  error: null,
  selectedClassItem: null,
  currentPage: 1,
  pageSize: 20,
  total: 0,
  pages: {}, //cached pages
  search: "",
};

// The Redux slice
const classSlice = createSlice({
  name: "classes",
  initialState: initialState,
  reducers: {
    getClassById: (state, id) => state.classes.find((item) => item.id === id),
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    // Selector to get the selected class by ID
    selectClassesById: (state) => state.classById,
    // Selector to get all classes
    // selectAllClasses: (state) => state.allIDs.map((id) => state.classById[id]),
    // Selector to get the currently selected class
    selectAllClassIds: (state) => state.allIDs,
    selectClassesStatus: (state) => state.status,
    selectClassesError: (state) => state.error,
    selectTotal: (state) => state.total,
    selectCurrentPage: (state) => state.currentPage,
    selectPageSize: (state) => state.pageSize,
    selectSearchTerm: (state) => state.search,
  },
  extraReducers: (builder) => {
    builder
      // Fetch all classes
      // .addCase(fetchClasses.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(fetchClasses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        const { data, total, page, search } = action.payload;
        state.total = total;
        state.classById = data.reduce((acc, cls) => {
          acc[cls.id] = cls;
          return acc;
        }, {});
        state.allIDs = data.map((cls) => cls.id);
        state.pages[`${page}-${search}`] = data; // Store classes search results
      })
      // .addCase(fetchClasses.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload.msg;
      // })

      // Fetch one class by ID
      // .addCase(fetchClass.pending, (state) => {
      //   state.status = "loading";
      // })
      .addCase(fetchClass.fulfilled, (state, action) => {
        state.status = "success";
        const { data, msg } = action.payload;
        state.error = msg;
        state.classById[data.id] = data;
        if (!state.allIDs.includes(data.id)) state.allIDs.push(data.id);
      })
      // .addCase(fetchClass.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      // })
      // Create a new class
      .addCase(createClass.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.classById[data.id] = data;
        state.allIDs.push(data.id);
        state.total += 1;
      })
      // .addCase(createClass.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      // })

      // Update an existing class
      .addCase(updateClass.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (state.classById[data.id]) {
          state.classById[data.id] = data;
        }
      })
      // .addCase(updateClass.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload.msg;
      // })
      // Delete a class
      .addCase(deleteClass.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (data) {
          state.status = "success";
          delete state.classById[data];
          state.allIDs = state.allIDs.filter((id) => id !== data);
          state.total -= 1;
        } else {
          // console.log("in here!!")
          state.status = "failed";
        }

        // for (const page in state.pageIds) {
        //   state.pageIds[page] = state.pageIds[page].filter(
        //     (id) => id !== classId
        //   );
        // }
      })
      // .addCase(deleteClass.rejected, (state, action) => {
      //   console.log("Error In Del", action.payload);
      //   state.status = "failed";
      //   state.error = action.payload;
      // })
      .addMatcher(
        isPending(
          fetchClasses,
          fetchClass,
          createClass,
          updateClass,
          deleteClass
        ),
        (action, state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(
          fetchClasses,
          fetchClass,
          createClass,
          updateClass,
          deleteClass
        ),
        (action, state) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});
export const {
  selectClassesById,
  // selectAllClasses,
  selectAllClassIds,
  selectClassesStatus,
  selectClassesError,
  selectTotal,
  selectCurrentPage,
  selectPageSize,
  selectSearchTerm,
} = classSlice.selectors;

export const { setCurrentPage, setSearchTerm, setPageSize } =
  classSlice.actions;

export const selectClassById = (clsId) =>
  createSelector([selectClassesById], (classById) => classById[clsId]);

export const selectAllClasses = createSelector(
  [selectClassesById, selectAllClassIds],
  (classById, allIDs) => allIDs.map((id) => classById[id])
);

export default classSlice.reducer;
