import "./App.css";
import {  Route,  Routes } from "react-router-dom";
import { Auth } from "./layout/auth";
import { Dashboard, Home } from "./layout";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<Home />}></Route>
      <Route path="/dashboard/*" element={<Dashboard />}></Route>
      {/* <Route path="/tutor/*" element={<TutorDashboard />}></Route> */}
      <Route path="/auth/*" element={<Auth />}></Route>
    </Routes>
  );
}

export default App;
