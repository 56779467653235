import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSessions, selectSessions, selectSessionsStatus } from "../../features/sessions/sessionSlice";

export const Registers = ({ tutorId }) => {
  // const [sessionsD, setSessions] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const dispatch = useDispatch()
  const sessions = useSelector(selectSessions);
  const status = useSelector(selectSessionsStatus)

  useEffect(() => {
    if(status === "idle"){
      dispatch(fetchSessions({ tutorId }))
    }
  }, [dispatch, status, tutorId]);

  return (
    <>
      {status === "pending" ? (
        <div className="flex items-center justify-center py-8">
          <div className="sm:flex-auto">
            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
              Fetching data ...
            </h5>
            <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-600 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-600 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-600 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-600 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : sessions.length === 0 ? (
        <div className="flex justify-start">
          <h1 className="text-lg my-3 font-normal py-5 text-gray-900">
            You're Currently not assigned to any session. Contact admin to get
            assigned.
          </h1>
        </div>
      ) : (
        <div className="col-span-full">
          {/* Header Section */}
          <div className="sm:flex sm:items-center sm:justify-between">
            <h1 className="text-lg font-semibold text-gray-900">
              Your Class Sessions
            </h1>
            <input
              type="search"
              className="my-2 block w-full sm:w-64 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              placeholder="Search sessions..."
              aria-label="Search sessions"
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </div>
          <ul className="space-y-3">
            {sessions
              .filter((item) =>
                searchParam.toLowerCase() === ""
                  ? true
                  : item.class.toLowerCase().includes(searchParam.toLowerCase())
              )
              .map((session) => (
                <li
                  key={session.id}
                  className="overflow-hidden bg-gray-100 border border-gray-300 px-6 py-2 shadow sm:rounded-md grid grid-cols-1 gap-4 content-start"
                >
                  <div className="col-span-full py-1 flex justify-between">
                    <h3 className="text-base font-light">
                      <b>Class:</b> {session.class}
                    </h3>
                    <h3 className="text-base font-light">
                      <b>Day:</b> {session.day}
                    </h3>
                    <h3 className="text-base font-light">
                      <b>Time:</b>
                      {session.start_at} - {session.end_at}
                    </h3>

                    <Link
                      to="class-register"
                      state={{ sessionId: session.id }}
                      className="rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2"
                    >
                      New Register
                    </Link>
                  </div>
                  {session.attendance.length !== 0 && (
                    <ul className="space-y-3">
                      {Object.entries(session.attendance).map(
                        ([date, data], index) => (
                          <li
                            key={index}
                            className="overflow-hidden bg-white px-6 py-2  shadow sm:rounded-md flex justify-between items-center"
                          >
                            <div>
                              <h4 className="block text font-medium text-gray-700 mb-1">
                                {date} | {session.start_at} - {session.end_at}{" "}
                              </h4>
                              <p className="block text-sm font-medium text-gray-700">
                                <span className="text-green-700">
                                  Present: {data.present_count}
                                </span>
                                ,{" "}
                                <span className="text-red-700">
                                  Absent: {data.absent_count}
                                </span>
                                ,{" "}
                                <span className="text-blue-700">
                                  Unmarked: {data.unmarked_count}
                                </span>
                              </p>
                            </div>
                            <div className="sm:flex items-center justify-between lg:grid lg:space-y-1">
                              <span
                                className={`inline-flex items-center px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset ${
                                  data.status === "saved"
                                    ? "bg-green-50 text-green-700 ring-green-600/20"
                                    : "bg-yellow-50 text-yellow-800 ring-yellow-600/20"
                                }`}
                              >
                                {data.status}
                              </span>

                              <Link
                                to="class-register"
                                state={{ sessionId: session.id, date }}
                                className="text-sm px-3 py-1 rounded-md border border-gray-300 text-blue-600 hover:bg-gray-50"
                              >
                                View
                              </Link>
                            </div>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};
