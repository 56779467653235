import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../../features/courses/courseSlice";
import {
  selectEnrollError,
  selectEnrollStatus,
  updateEnrollment,
} from "../../features/enrollment/enrollSlice";
import Alert from "../../components/common/alert";

const UpdateEnrollment = ({ show, handleClose, enrollment }) => {
  const [selectedClass, setSelectedClass] = useState("");
  const [enrollmentUpdates, setEnrollmentUpdates] = useState({});
  const [showClasses, setShowClasses] = useState(true);
  const [showSessions, setShowSessions] = useState(false);
  const [classes, setClasses] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [sessionInputs, setSessionInputs] = useState([]);
  const [saturdaySession, setSatSession] = useState("");
  const [sundaySession, setSunSession] = useState("");
  const [chapelSession, setChapelSession] = useState("");
  const [courseItem, setCourseItem] = useState({});
  const [classId, setClassId] = useState();
  const [alert, setAlert] = useState({ show: false, msg: "", type: "" });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const courseStatus = useSelector(selectCourseStatus);
  const courses = useSelector(selectCourses);
  const status = useSelector(selectEnrollStatus);
  const error = useSelector(selectEnrollError);

  useEffect(() => {
    // console.log("courseStatus", courseStatus);
    if (courseStatus === "idle") {
      dispatch(
        fetchCourses({
          page: null,
          pageSize: null,
          search: null,
        })
      );
    }

    setTimeout(() => {
      if (alert.show) {
        setAlert({ ...alert, show: false });
      }
    }, 5000);
  }, [alert, courseStatus, dispatch]);

  const handleCourseInput = (e) => {
    setShowSessions(false);
    // // setSessionsInput([]);
    // setShowClasses(false);
    setSatSession("");
    setSunSession("");
    setSelectedClass("");
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );

    if (course && course.classes.length !== 0) {
      setCourseItem(course);
      setClasses(course.classes);
      setShowClasses(true);
      if (course.classes.length > 1) {
        // setShowClasses(true);
        setShowSessions(false);
      } else {
        const courseClass = course.classes[0];
        setClassId(courseClass.id);
        // console.log("sessions", sessionsInput);
        //  if (courseClass.sessions.length !== 0) {
        //    setSessions(courseClass.sessions);
        //    setShowSessions(true);
        //  } else {
        //    setShowSessions(false);
        //  }
        // console.log("classId", course.classes[0].id);
        // setEnrollmentData({
        //   ...enrollmentData,
        //   class_id: course.classes[0].id,
        // });
      }
      // setEnrollmentData({
      //   ...enrollmentData,
      //   amount:
      //     course.course_price !== "None"
      //       ? parseFloat(course.course_price)
      //       : 1.0,
      // });
    }
  };
  const handleClassInputChange = (e) => {
    let val = e.target.value;
    setSelectedClass(val);
    const class_ = classes.find((item) => item.id === parseInt(val));
    if (class_) {
      setClassId(class_.id);
      if (class_.sessions.length !== 0) {
        setSessions(class_.sessions);
        setShowSessions(true);
      } else {
        setShowSessions(false);
      }
      // setSessionChecked(new Array(class_.sessions.length).fill(false));
      setEnrollmentUpdates({
        ...enrollmentUpdates,
        class_id: parseInt(val),
      });
    } else {
      setShowSessions(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(
    //   "sessions",
    //   [parseInt(saturdaySession), parseInt(chapelSession)],
    //   classId
    // );
    try {
      // await dispatch(enrollStudent(payload))
      const sessions = []

      if (!isNaN(parseInt(saturdaySession))) {
        sessions.push(parseInt(saturdaySession));
      }
      if (!isNaN(parseInt(sundaySession))) {
        sessions.push(parseInt(sundaySession));
      }
      if (!isNaN(parseInt(chapelSession))) {
        sessions.push(parseInt(chapelSession));
      }
      // console.log("payload", payload);
      await dispatch(
        updateEnrollment({
          ...enrollmentUpdates,
          id: enrollment.id,
          student_id: enrollment.student_id,
          sessions: sessions
        })
      ).unwrap();
      console.log("Error", error, status)
      setAlert({ ...alert, show: true, msg: error, type: status });
    } catch (err) {
      console.log(error);
      setAlert({ ...alert, show: true, msg: error, type: status });

      setShowClasses(false);
      setShowSessions(false);
    } finally {
      setShowClasses(false);
      setShowSessions(false);
      e.target.reset();
    }
  };

  return (
    <>
      {show && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
              <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-md sm:max-w-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h1 className="text-lg font-semibold text-gray-900">
                      Update Student Enrollment
                    </h1>
                    <button
                      type="button"
                      className="p-2 rounded-full text-red-500 hover:bg-red-100"
                      onClick={handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="mx-auto max-w-lg">
                    <div className="col-span-full">
                      {alert.show && (
                        <Alert
                          show={alert.show}
                          content={error}
                          type={status}
                        ></Alert>
                      )}
                    </div>
                    <form className="mt-6" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-12 gap-x-4 gap-y-4 p-4 text-left border border-gray-200 rounded-md shadow-sm">
                        <div className="col-span-full">
                          <label
                            htmlFor="select-course"
                            className="block text font-medium text-gray-700"
                          >
                            Course
                          </label>
                          <div className="mt-1">
                            <select
                              id="select-course"
                              name="course"
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            //   defaultValue={enrollment.class.course_id}
                            // defaultValue="Select Class"
                              onChange={handleCourseInput}
                            >
                              <option value="">Select Course</option>
                              {courses.map((course, index) => (
                                <option key={index} value={course.id}>
                                  {course.course_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {showClasses && (
                          <div className="col-span-full">
                            <label
                              htmlFor="session"
                              className="block text font-medium text-gray-700"
                            >
                              Class
                            </label>
                            <div className="mt-1">
                              <select
                                id="class_"
                                name="class"
                                placeholder="Select Class"
                                onChange={handleClassInputChange}
                                value={selectedClass}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">Select Class</option>
                                {classes.map((class_, index) => (
                                  <option key={index} value={class_.id}>
                                    {class_.class_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}

                        {showSessions && (
                          <div className="col-span-full space-y-3">
                            <label
                              htmlFor="session"
                              className="block text font-medium text-gray-700"
                            >
                              Saturday Session
                            </label>
                            <div className="mt-1">
                              <select
                                onChange={(e) => setSatSession(e.target.value)} // For first session input
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">
                                  Select Saturday Session
                                </option>
                                {sessions
                                  .filter(
                                    (item) =>
                                      item.day.toLowerCase() === "saturday"
                                  )
                                  .map((session, index) => (
                                    <option key={index} value={session.id}>
                                      {session.start_at} - {session.end_at}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <label
                              htmlFor="session"
                              className="block text font-medium text-gray-700"
                            >
                              Sunday Session
                            </label>
                            <div className="mt-1">
                              <select
                                onChange={(e) => setSunSession(e.target.value)} // For second session input
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">Select Sunday Session</option>
                                {sessions
                                  .filter(
                                    (item) =>
                                      item.day.toLowerCase() === "sunday"
                                  )
                                  .map((session, index) => (
                                    <option key={index} value={session.id}>
                                      {session.start_at} - {session.end_at}
                                    </option>
                                  ))}
                              </select>
                            </div>

                            <label
                              htmlFor="session"
                              className="block text font-medium text-gray-700"
                            >
                              Sunday Chapel Session
                            </label>
                            <div className="mt-1">
                              <select
                                onChange={(e) =>
                                  setChapelSession(e.target.value)
                                } // For second session input
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              >
                                <option value="">
                                  Select Sunday Chapel Session
                                </option>
                                {sessions
                                  .filter(
                                    (item) =>
                                      item.day.toLowerCase() === "chapel"
                                  )
                                  .map((session, index) => (
                                    <option key={index} value={session.id}>
                                      {session.start_at} - {session.end_at}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between">
                        <button
                          onClick={handleClose}
                          className="mt-6 rounded-md text-sm text-blue-600 px-4 py-2 hover:bg-white"
                        >
                          Cancel
                        </button>
                        <button
                          // disabled={
                          //   saturdaySession === "" ||
                          //   sundaySession === "" ||
                          //   chapelSession === ""
                          // }
                          type="submit"
                          className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateEnrollment;
