import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { routes } from "../routes/routes";
import { getOidToken, getUserDetails } from "../utils/helpers/authHelpers";
import { useDispatch } from "react-redux";
import { setLogin } from "../features/auth/authSlice";
import { Header, Stats } from "../components/Header";
import { useEffect, useState } from "react";
import PasswordUpdateModal from "./PasswordUpdateModal";
const RouteGuard = ({ children }) => {
  const token = getOidToken();
  return token ? children : <Navigate to="/auth/login" />;
};

export const Dashboard = () => {
  const [authType, setAuthType] = useState(null);
  const [showPasswordUpdate, setPasswordUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const authToken = getOidToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const user = getUserDetails();
    setUserInfo(user)
     if (!user.last_login) {
       setPasswordUpdate(true);
     }
    if (authToken) {
      dispatch(setLogin(true));
      setAuthType(user.user_type);
    } else {
      navigate("/auth/login");
    }
  }, [authToken, dispatch, navigate]);

  if (showPasswordUpdate) {
    return (
      <PasswordUpdateModal
        show={true}
        // handleClose={() => setPasswordUpdate(false)}
        user={userInfo}
      />
    );
  } else {
    switch (authType) {
      case "admin":
        return (
          <>
            <RouteGuard>
              <div className="h-full bg-gray-50">
                {/* Off-canvas menu for mobile */}
                {sidebarOpen && (
                  <div
                    className="fixed inset-0 z-50 lg:hidden"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div
                      className="fixed inset-0 bg-gray-900/80"
                      onClick={() => setSidebarOpen(false)}
                    ></div>
                    <div className="fixed inset-0 flex">
                      <div
                        className="relative mr-16 flex w-full max-w-xs flex-1 transform transition-transform duration-300 ease-in-out"
                        style={{
                          transform: sidebarOpen
                            ? "translateX(0)"
                            : "-translateX-full",
                        }}
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <svg
                              className="h-6 w-6 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* Sidebar component */}
                        <Sidebar authType={authType} />
                      </div>
                    </div>
                  </div>
                )}

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                  <Sidebar authType={authType} />
                </div>
                <div className="lg:pl-72 space-y-2 bg-gray-100">
                  {/* Header */}
                  <Header setSidebarOpen={setSidebarOpen} />
                  <Stats />
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "admin" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </div>

              {/* <Header />
              <Sidebar authType={authType} />
              <Stats />
              <div className="lg:pl-72 space-y-2">
                <main className="py-10">
                  <div className="px-4 sm:px-6 lg:px-8"></div>
                </main>
              </div> */}
            </RouteGuard>
          </>
        );
      case "cashier":
        return (
          <>
            <RouteGuard>
              <div className="h-full bg-gray-50">
                {/* Off-canvas menu for mobile */}
                {sidebarOpen && (
                  <div
                    className="fixed inset-0 z-50 lg:hidden"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div
                      className="fixed inset-0 bg-gray-900/80"
                      onClick={() => setSidebarOpen(false)}
                    ></div>
                    <div className="fixed inset-0 flex">
                      <div
                        className="relative mr-16 flex w-full max-w-xs flex-1 transform transition-transform duration-300 ease-in-out"
                        style={{
                          transform: sidebarOpen
                            ? "translateX(0)"
                            : "-translateX-full",
                        }}
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <svg
                              className="h-6 w-6 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                        {/* Sidebar component */}
                        <Sidebar authType={authType} />
                      </div>
                    </div>
                  </div>
                )}

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                  <Sidebar authType={authType} />
                </div>

                <div className="lg:pl-72 space-y-2">
                  {/* Header */}
                  <Header setSidebarOpen={setSidebarOpen} />
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "finance" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </div>

              {/* <Header />
              <Sidebar authType={authType} />
              <Stats />
              <div className="lg:pl-72 space-y-2">
                <main className="py-10">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <Routes>
                      {routes.map(
                        ({ layout, pages }) =>
                          layout === "finance" &&
                          pages.map(({ path, element }) => (
                            <Route exact path={path} element={element} />
                          ))
                      )}
                    </Routes>
                  </div>
                </main>
              </div> */}
            </RouteGuard>
          </>
        );
      case "tutor":
        return (
          <>
            <RouteGuard>
              <div className="h-full bg-gray-50">
                {/* Off-canvas menu for mobile */}
                {/* {sidebarOpen && (
                  <div
                    className="fixed inset-0 z-50 lg:hidden"
                    role="dialog"
                    aria-modal="true"
                  >
                    <div
                      className="fixed inset-0 bg-gray-900/80"
                      onClick={() => setSidebarOpen(false)}
                    ></div>
                    <div className="fixed inset-0 flex">
                      <div
                        className="relative mr-16 flex w-full max-w-xs flex-1 transform transition-transform duration-300 ease-in-out"
                        style={{
                          transform: sidebarOpen
                            ? "translateX(0)"
                            : "-translateX-full",
                        }}
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <svg
                              className="h-6 w-6 text-white"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div> */}
                        {/* Sidebar component */}
                        {/* <Sidebar authType={authType} />
                      </div>
                    </div>
                  </div>
                )} */}

                {/* Static sidebar for desktop */}
                {/* <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"> */}
                  {/* <Sidebar authType={authType} /> */}
                {/* </div> */}

                  {/* Header */}
                  <Header setSidebarOpen={setSidebarOpen} />
                <div className="lg:px-72">
                  <Routes>
                    {routes.map(
                      ({ layout, pages }) =>
                        layout === "tutor" &&
                        pages.map(({ path, element }) => (
                          <Route exact path={path} element={element} />
                        ))
                    )}
                  </Routes>
                </div>
              </div>
{/* 
              <Header />
              <Sidebar authType={authType} />
              <div className="lg:pl-72 space-y-2">
                <main className="py-10">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <Routes>
                      {routes.map(
                        ({ layout, pages }) =>
                          layout === "tutor" &&
                          pages.map(({ path, element }) => (
                            <Route exact path={path} element={element} />
                          ))
                      )}
                    </Routes>
                  </div>
                </main>
              </div> */}
            </RouteGuard>
          </>
        );

      default:
        break;
    }
  }

  // return (
  //   <>
  //     <RouteGuard>
  //       <Header />
  //       {(authType === "admin" || authType === "cashier") && <Stats />}
  //       {(authType === "admin" || authType === "cashier") && (
  //         <Sidebar authType={authType} />
  //       )}

  //       {authType === "admin" ? (
  //         <div className="lg:pl-72 space-y-2">
  //           <main className="py-10">
  //             <div className="px-4 sm:px-6 lg:px-8">
  //               <Routes>
  //                 {routes.map(
  //                   ({ layout, pages }) =>
  //                     layout === "admin" &&
  //                     pages.map(({ path, element }) => (
  //                       <Route exact path={path} element={element} />
  //                     ))
  //                 )}
  //               </Routes>
  //             </div>
  //           </main>
  //         </div>
  //       ) : authType === "cashier" ? (
  //         <div className="lg:pl-72 space-y-2">
  //           <main className="py-10">
  //             <div className="px-4 sm:px-6 lg:px-8">
  //               <Routes>
  //                 {routes.map(
  //                   ({ layout, pages }) =>
  //                     layout === "finance" &&
  //                     pages.map(({ path, element }) => (
  //                       <Route exact path={path} element={element} />
  //                     ))
  //                 )}
  //               </Routes>
  //             </div>
  //           </main>
  //         </div>
  //       ) : (
  //         <Routes>
  //           {routes.map(
  //             ({ layout, pages }) =>
  //               layout === "tutor" &&
  //               pages.map(({ path, element }) => (
  //                 <Route exact path={path} element={element} />
  //               ))
  //           )}
  //         </Routes>
  //       )}
  //     </RouteGuard>
  //   </>
  // );
};
