import React from "react";

export const PrivacyPolicy = () => {
  return (
    <>
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <div className="hidden pb-12 pt-12 lg:flex">
          <a href="/">
            <span className="sr-only">IYF Free Academy</span>
            <img src="./assets/png/iyflogo.png" alt="" className="h-20 w-auto" />
          </a>
        </div>

        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Privacy Policy for International Youth Fellowship
        </h1>

        <p>
          <strong>Effective Date:</strong> Sunday 7 Apr, 2024
        </p>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Introduction</h2>
          <p>
            Welcome to the International Youth Fellowship. We are committed to
            protecting the privacy and security of the personal information we
            collect from our students. This Privacy Policy outlines our
            practices regarding the collection, use, and disclosure of your
            information through our enrollment processes and participation in
            our courses.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Information We Collect</h2>
          <p>
            We collect the following personal information from our students:
          </p>
          <ul className="list-disc pl-5">
            <li>Name</li>
            <li>Date of Birth</li>
            <li>ID Number</li>
            <li>Name of Parent or Guardian</li>
            <li>ID Number of Parent or Guardian</li>
            <li>Current Residence</li>
            <li>Course Selection</li>
          </ul>
          <p>
            Additionally, we gather information on how you learned about our
            academy, which may include:
          </p>
          <ul className="list-disc pl-5">
            <li>Flier</li>
            <li>Social Media</li>
            <li>Friend / Family</li>
            <li>GNM Member</li>
            <li>Continuing Student</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">How We Use Your Information</h2>
          <ul className="list-disc pl-5">
            <li>Manage course enrollments and maintain academic records.</li>
            <li>
              Communicate with you about your courses and any changes to our
              services.
            </li>
            <li>Provide support and respond to your inquiries.</li>
            <li>
              Conduct statistical analyses to improve our educational offerings.
            </li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">How We Share Your Information</h2>
          <p>
            We do not sell or rent personal information to third parties. Your
            data may be shared with third-party service providers who perform
            services on our behalf, such as data management and email services.
            These providers are bound by strict confidentiality agreements to
            ensure the security of your data.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Data Security</h2>
          <p>
            We implement various security measures to ensure the safety of your
            personal information both online and offline. These include
            encryption, access controls, and secure storage practices. Our
            website is also SSL-certified to enhance security.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Your Rights</h2>
          <p>
            You have the right to access, correct, or delete your personal
            information held by us. You may also opt out of certain uses of your
            information. If you wish to exercise these rights, please contact us
            at the details provided below.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy periodically to reflect changes in
            our practices. We will notify you of any significant changes and
            post the updated policy on our website.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="font-semibold text-lg">Contact Us</h2>
          <p>
            If you have any questions or concerns about our use of your personal
            information, please contact us at Phone: 0708 333 444
          </p>
        </section>

        <section className="mt-6 mb-4">
          <h2 className="font-semibold text-lg">Consent</h2>
          <p>
            Please read this policy carefully. By checking the box on our
            enrollment form, you confirm that you understand how we use your
            information and agree to the collection of your data for educational
            purposes.
          </p>
        </section>
      </div>
    </>
  );
};

