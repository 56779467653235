import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "../../components/common/alert";

import {
  fetchCourses,
  selectCourses,
  selectCourseStatus,
} from "../../features/courses/courseSlice";

import { registerStudent } from "../../features/registrations/registrationSlice";
import { enrollStudent } from "../../features/enrollment/enrollSlice";

// const courseList = [
//   {
//     course_code: "GD001",
//     course_details: "graphic design course",
//     course_name: "Graphics",
//     course_price: null,
//     id: 1,
//   },
//   {
//     course_code: "WD001",
//     course_details: "Web design course",
//     course_name: "Web Design",
//     course_price: null,
//     id: 3,
//   },
//   {
//     course_code: "GD002",
//     course_details: "design course",
//     course_name: "GraphicsDesign",
//     course_price: null,
//     id: 4,
//   },
// ];

export const EnrollCard = ({ student }) => {
  // console.log("Reg student :::", student);
  return (
    <>
      <div className="mx-auto max-w-lg">
        <div className="grid grid-cols-12 gap-x-4 gap-y-6">
          <div className="col-span-full">
            <div className="p-4 text-center space-y-2 bg-white border border-gray-200 rounded-xl shadow-sm">
              {/* {student.enroll_status === "active" && ( */}
              <div className="mb-4 flex items-center justify-center">
                <svg
                  className="h-10 w-10"
                  width="72"
                  height="73"
                  viewBox="0 0 72 73"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.255859"
                    y="0.899719"
                    width="71.4893"
                    height="71.4893"
                    rx="35.7446"
                    fill="#00B267"
                  />
                  <path
                    d="M51.7795 26.6616L30.5259 47.9152L20.8652 38.2545"
                    stroke="white"
                    strokeWidth="3.86428"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {/* )} */}
              <h1 className="text-2xl font-bold">{student.full_name}</h1>
              <h1 className="text-xl font-normal">+{student.msisdn}</h1>
              <h1 className="text-xl font-normal">{student.registration_no}</h1>
            </div>
          </div>
          <div className="col-span-full">
            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
              <dl className="mt-2 divide-y space-x-2 divide-gray-200 ">
                <div className="flex justify-center py-3 text-sm font-medium">
                  <dt className="text-gray-900 text-xl">
                    Your Enrollment Info
                  </dt>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Status</dt>
                  <dd className="text-green-600 text-bold">Active</dd>
                  {/* {student.enroll_status === "pending" ? (
                    <dd className="text-red-700 text-bold">Pending Payment</dd>
                  ) : (
                    <dd className="text-green-600 text-bold">Active</dd>
                  )} */}
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Course</dt>
                  <dd className="text-gray-900">{student.course_name}</dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Class</dt>
                  <dd className="text-gray-900">
                    {/* {student.enrollments[0]?.class_name} */}
                    {student.class_name}
                  </dd>
                </div>
                {student.sessions !== 0 &&
                  student.sessions?.map((session, idx) => (
                    <div
                      key={idx}
                      className="flex justify-between py-3 text-sm font-medium"
                    >
                      <dt className="text-gray-500">{session.day} Session</dt>
                      <dd className="text-gray-900">
                        {session.from} - {session.to}
                      </dd>
                    </div>
                  ))}
              </dl>
            </div>
          </div>
          {/* {student.enroll_status !== "active" && (
            <div className="col-span-full">
              <Link
                to="/mpesa-express"
                state={{ enrollment_id: student.enrollments[0]?.id }}
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
              >
                Pay Now
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export const ConfirmRegistration = ({
  studentData: { student, enrollment, course_name, class_name },
}) => {
  const [status, setStatus] = useState(false);
  const [regNo, setRegNo] = useState("false");
  const [alert, setAlert] = useState({
    show: false,
    msg: "",
    type: "",
  });
  const dispatch = useDispatch();
  console.log(student);
  // const status = useSelector(selectRegistrationState);
  // const error = useSelector(selectErrorMsg);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(registerStudent(student))
      .unwrap()
      .then(async (results) => {
        const { data, msg, status } = results;
        if (status !== 201 || data.exists) {
          setAlert({ ...alert, show: true, msg: msg, type: "failed" });
        } else {
          const payload = {
            ...enrollment,
            student_id: data.id,
          };
          // console.log("payload", payload);
          await dispatch(enrollStudent(payload))
            .unwrap()
            .then((res) => {
              const { data, msg, status } = res;
              setRegNo(data.registration_no);
              if (status !== 200) {
                setAlert({ ...alert, show: true, msg: msg, type: "failed" });
              } else {
                setStatus(true);
                setAlert({ ...alert, show: true, msg: msg, type: "success" });
              }
            });
        }
      });
  };
  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        setAlert({ ...alert, show: false });
      }, 3000);
    }
  }, [alert]);

  return (
    <>
      <div className="mx-auto max-w-lg mt-4">
        {status ? (
          <div>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full">
                <div className="p-8 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <div className="mb-4 flex items-center justify-center">
                    <svg
                      className="h-15 w-15"
                      width="52"
                      height="53"
                      viewBox="0 0 72 75"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.255859"
                        y="0.899719"
                        width="71.4893"
                        height="71.4893"
                        rx="35.7446"
                        fill="#00B267"
                      />
                      <path
                        d="M51.7795 26.6616L30.5259 47.9152L20.8652 38.2545"
                        stroke="white"
                        strokeWidth="3.86428"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="text-xl font-normal text-center pb-4">
                    Registration successful
                  </p>
                  <p>
                    <span className="text-lg font-bold">Student Name: </span>
                    <span className="text-xl font-normal">
                      {student.full_name}
                    </span>
                  </p>
                  <p>
                    <span className="text-lg font-bold">Student Number: </span>
                    <span className="text-xl font-normal">{regNo}</span>
                  </p>
                  <p>
                    <span className="text-lg font-bold">Course: </span>
                    <span className="text-xl font-normal">{course_name}</span>
                  </p>
                  <p>
                    <span className="text-lg font-bold">Class: </span>
                    <span className="text-xl font-normal">{class_name}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-12 gap-x-4 gap-y-6">
            <div className="col-span-full">
              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                <div>
                  <h3 className="font-medium text-gray-900">
                    Confirm Registration
                  </h3>
                  <div className="flex justify-center col-span-full">
                    {alert.show && (
                      <Alert
                        show={alert.show}
                        content={alert.msg}
                        type={alert.type}
                      ></Alert>
                    )}
                  </div>
                  <dl className="mt-2 divide-y space-x-2 divide-gray-200 border-t border-gray-200">
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Name</dt>
                      <dd className="text-gray-900">{student.full_name}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Date of Birth</dt>
                      <dd className="text-gray-900">{student.dob}</dd>
                    </div>
                    {student.id_number && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">ID Number</dt>
                        <dd className="text-gray-900">{student.id_number}</dd>
                      </div>
                    )}

                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Phone Number</dt>
                      <dd className="text-gray-900">{student.msisdn}</dd>
                    </div>
                    {student.guardian_name && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Name of Parent</dt>
                        <dd className="text-gray-900">
                          {student.guardian_name}
                        </dd>
                      </div>
                    )}
                    {student.guardian_id_no && (
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">
                          ID No. of Parent or Guardian
                        </dt>
                        <dd className="text-gray-900">
                          {student.guardian_id_no}
                        </dd>
                      </div>
                    )}

                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Current Residence</dt>
                      <dd className="text-gray-900">{student.residence}</dd>
                    </div>
                    {/* <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Course</dt>
                        <dd className="text-gray-900">UI/UX</dd>
                      </div> */}
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">
                        How did you know about weekend academy?
                      </dt>
                      <dd className="text-gray-900">{student.channel}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">
                        New or Continuing student?
                      </dt>
                      <dd className="text-gray-900">
                        {student.enrollment_status}
                      </dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Course</dt>
                      <dd className="text-gray-900">{course_name}</dd>
                    </div>
                    <div className="flex justify-between py-3 text-sm font-medium">
                      <dt className="text-gray-500">Class</dt>
                      <dd className="text-gray-900">{class_name}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <button
                className="flex justify-between rounded-md text-sm bg-blue-600 text-white px-4 py-2 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                type="button"
                onClick={handleSubmit}
              >
                Complete
              </button>

              <div className="flex justify-between">
                {/* <Link
                    to="/"
                    className="rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-600"
                  >
                    Register Another
                  </Link> */}
                {/* <Link
                    to="/enroll"
                    // type="submit"
                    state={{ id: student.id }}
                    className="flex justify-between rounded-md text-sm bg-blue-600 text-white px-4 py-2 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Proceed to Pay
                  </Link> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const RegisterStudent = ({ show, handleClose }) => {
  const [student, setStudent] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [age, setAge] = useState(0);
  const [showId, setShowId] = useState(true);
  const [dobDay, setDobDay] = useState(0);
  const [dobMonth, setDobMonth] = useState(0);
  const [dobYear, setDobYear] = useState(0);
  const [enrollmentData, setEnrollmentData] = useState({});
  const [classes, setClasses] = useState([]);
  const [courseItem, setCourseItem] = useState({});
  const [confirmationInfo, setShowConfirmationInfo] = useState({});
  const [showClasses, setShowClasses] = useState(false);
  const [classItem, setClassItem] = useState();
  const [showConfirm, setShowConfirm] = useState(false);

  const months = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const dispatch = useDispatch();

  const courseStatus = useSelector(selectCourseStatus);
  const courses = useSelector(selectCourses);

  const handleCourseInput = (e) => {
    // setShowSessions(false);
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );

    if (course && course.classes.length !== 0) {
      setCourseItem(course);
      setClasses(course.classes);
      if (course.classes.length > 1) {
        setShowClasses(true);
      } else {
        setClassItem(course.classes[0]);
        setShowClasses(false);
        setEnrollmentData({
          ...enrollmentData,
          class_id: course.classes[0].id,
          amount: 1,
        });
      }
    }
  };

  const handleClassInputChange = (e) => {
    const class_ = courseItem.classes.find(
      (item) => item.id === parseInt(e.target.value)
    );
    if (class_) {
      setClassItem(class_);
      setEnrollmentData({
        ...enrollmentData,
        class_id: parseInt(e.target.value),
      });
    } else {
    }
  };
  // Function to calculate age
  const calculateAge = useCallback(() => {
    if (!dobYear || !dobMonth || !dobDay) return;
    const today = new Date();
    const birthDate = new Date(`${dobYear}-${dobMonth}-${dobDay}`);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() + 1 - birthDate.getMonth(); // Adjust month index
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setAge(age);
    setShowId(age > 18);
  }, [dobDay, dobMonth, dobYear]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setStudent((student) => ({ ...student, [name]: value }));
  }, []);

  const handleConfirmRegistration = () => {
    const Dt = [
      dobDay.toString(),
      dobMonth.toString(),
      dobYear.toString(),
    ].join("-");
    const data = {
      student: { ...student, dob: Dt },
      enrollment: enrollmentData,
      course_name: courseItem.course_name,
      class_name: classItem.class_name,
    };
    setShowConfirmationInfo(data);
    setShowConfirm(true);
  };

  useEffect(() => {
    if (courseStatus === "idle") {
      dispatch(
        fetchCourses({
          page: null,
          pageSize: null,
          search: null,
        })
      );
    }
    if (showLoading) {
      setTimeout(() => {
        setShowLoading(false);
      }, 2000);
    }

    calculateAge();
  }, [
    showLoading,
    dispatch,
    courses.length,
    dobDay,
    dobMonth,
    dobYear,
    calculateAge,
    courseStatus,
  ]);
  // Calculate max days based on month and leap year logic
  const maxDay =
    dobMonth === "2"
      ? dobYear % 4 === 0 && (dobYear % 100 !== 0 || dobYear % 400 === 0)
        ? 29
        : 28
      : ["4", "6", "9", "11"].includes(dobMonth)
      ? 30
      : 31;

  return (
    <>
      {show && (
        <div
          className="fixed inset-10 z-10 w-screen overflow-y-auto"
          role="dialog"
          aria-modal="true"
        >
          {/* Background overlay */}
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
            aria-hidden="true"
          ></div>

          {/* Modal container */}
          <div className="flex min-h-screen items-center justify-center p-4 sm:p-6">
            <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full sm:max-w-sm md:max-w-md lg:max-w-lg">
              <div className="px-4 py-5 sm:p-6">
                {/* Modal header */}
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-lg sm:text-xl font-semibold text-gray-900">
                    Register New Student
                  </h1>
                  <button
                    type="button"
                    className="p-2 rounded-full text-red-500 hover:bg-red-100"
                    onClick={handleClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                {showConfirm ? (
                  <ConfirmRegistration studentData={confirmationInfo} />
                ) : (
                  <div className="mx-auto max-w-lg">
                    {
                      <form className="mt-6">
                        <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="select-season"
                                className="block text font-medium text-gray-700"
                              >
                                Season
                              </label>
                              <div className="mt-1">
                                <select
                                  id="select-season"
                                  name="season"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  defaultValue="Select Season"
                                  onChange={handleInputChange}
                                >
                                  {/* <option value="" selected>Select Season</option> */}
                                  <option value="7">Season 7</option>
                                  <option value="8">Season 8</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="full_name"
                                className="block text font-medium text-gray-700"
                              >
                                Full Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="full_name"
                                  id="full_name"
                                  autoComplete="Full Name"
                                  placeholder="Full Name"
                                  onChange={handleInputChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  required
                                ></input>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="msisdn"
                                className="block text font-medium text-gray-700"
                              >
                                Phone Number
                              </label>
                              <div className="mt-1">
                                <input
                                  // type="phone"
                                  name="msisdn"
                                  id="msisdn"
                                  autoComplete="Phone Number"
                                  placeholder="Phone Number"
                                  pattern="((\+|00)?[1-9]{2}|0)[1-9]([0-9]){8}"
                                  onChange={handleInputChange}
                                  // onInput={(e) =>
                                  //   e.target.setCustomValidity(
                                  //     "Phone No. must be 10 digits in the format 0712345678"
                                  //   )
                                  // }
                                  // onInvalid={(e) =>
                                  //   e.target.setCustomValidity(
                                  //     "Phone No. must be 10 digits in the format 0712345678"
                                  //   )
                                  // }
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  required
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="dob"
                                className="block text font-medium text-gray-700"
                              >
                                Date of Birth
                              </label>
                              <div className="grid grid-cols-3 gap-4 mt-2">
                                <div>
                                  <select
                                    id="dob-month"
                                    name="dob-month"
                                    defaultValue="Month"
                                    // className="block w-full rounded-md border-0 pl-3 py-1 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    className="block border  text-gray-900 text-sm rounded-lg w-full  border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    onChange={(e) =>
                                      setDobMonth(e.target.value)
                                    }
                                  >
                                    <option value="">Month</option>
                                    {months.map((month, index) => (
                                      <option key={index} value={index + 1}>
                                        {month}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div>
                                  <input
                                    type="number"
                                    id="dob"
                                    name="dob"
                                    placeholder="Day"
                                    max={maxDay}
                                    min={1}
                                    // value={dobDay}
                                    onChange={(e) => setDobDay(e.target.value)}
                                    className="block border text-gray-900 text-sm rounded-lg w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    required
                                  />
                                </div>
                                <div>
                                  <input
                                    type="number"
                                    id="dob-year"
                                    name="dob-year"
                                    placeholder="Year"
                                    max={2008} // Assuming no one under 16 can register (year > 2008)
                                    min={1900}
                                    // value={dobYear}
                                    onChange={(e) => setDobYear(e.target.value)}
                                    className="block border text-gray-900 text-sm rounded-lg w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          {showId ? (
                            <div className="col-span-full">
                              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                                <label
                                  htmlFor="id-number"
                                  className="block text font-medium text-gray-700"
                                >
                                  ID Number
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    id="id_number"
                                    name="id_number"
                                    placeholder="ID Number"
                                    pattern="[A-Za-z0-9]{4,15}"
                                    value={student?.id_number}
                                    onChange={handleInputChange}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-span-full">
                              <div className="grid grid-cols-2 gap-4">
                                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                                  <label
                                    htmlFor="guardian_name"
                                    className="block text font-medium text-gray-700"
                                  >
                                    Name of Parent
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="guardian_name"
                                      id="guardian_name"
                                      placeholder="Parent Full Name"
                                      value={student?.guardian_name}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                                  <label
                                    htmlFor="guardian_id_no"
                                    className="block text font-medium text-gray-700"
                                  >
                                    Parent or Guardian ID No.
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      name="guardian_id_no"
                                      id="guardian_id_no"
                                      placeholder="Parent Guardian ID Number"
                                      pattern="[0-9]{8}"
                                      value={student?.guardian_id_no}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-span-full mt-2">
                                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                                  <label
                                    htmlFor="parent-phone"
                                    className="block text font-medium text-gray-700"
                                  >
                                    Parent/Guardian Phone Number
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      type="text"
                                      id="parent-phone"
                                      name="guardian_msisdn"
                                      placeholder="eg. 0700 123 456"
                                      pattern="[A-Za-z0-9]{4,15}"
                                      value={student?.guardian_msisdn}
                                      onChange={handleInputChange}
                                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="current_residence"
                                className="block text font-medium text-gray-700"
                              >
                                Current Residence
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="residence"
                                  id="current_residence"
                                  placeholder="e.g Githiurai"
                                  autoComplete="Current Residence"
                                  onChange={handleInputChange}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  required
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-full">
                            <p className="block text-xl border border-gray-300 font-medium text-gray-800 mb-4 text-center bg-gray-200 rounded-md p-2">
                              Course Enrollment
                            </p>
                          </div>

                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <label
                                htmlFor="select-course"
                                className="block text font-medium text-gray-700"
                              >
                                Select Course
                              </label>
                              <div className="mt-1">
                                <select
                                  id="select-course"
                                  name="course"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  defaultValue="Select Course"
                                  onChange={handleCourseInput}
                                >
                                  <option value="">Select Course</option>
                                  {courses.map((course, index) => (
                                    <option key={index} value={course.id}>
                                      {course.course_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          {showClasses && (
                            <div className="col-span-full">
                              <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                                <label
                                  htmlFor="session"
                                  className="block text font-medium text-gray-700"
                                >
                                  Select Class
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="class_"
                                    name="class"
                                    placeholder="Select Class"
                                    onChange={handleClassInputChange}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value="">Select Class</option>
                                    {classes.map((class_, index) => (
                                      <option key={index} value={class_.id}>
                                        {class_.class_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <fieldset>
                                <legend className="block text font-medium text-gray-700">
                                  How did you know about weekend academy
                                </legend>
                                <div className="mt-3 space-y-2">
                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="channel-flier"
                                      name="channel"
                                      type="radio"
                                      value="Flier"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="channel-flier"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Flier
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="channel-sm"
                                      name="channel"
                                      type="radio"
                                      value="Social Media"
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      onChange={handleInputChange}
                                      required
                                    ></input>
                                    <label
                                      htmlFor="channel-sm"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Social Media
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="channel-fam"
                                      name="channel"
                                      type="radio"
                                      value="Friend/Family"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="channel-fam"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Friend / Family
                                    </label>
                                  </div>

                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="channel-member"
                                      name="channel"
                                      type="radio"
                                      value="GNM Member"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="channel-member"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      GNM Member
                                    </label>
                                  </div>

                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="channel-student"
                                      name="channel"
                                      type="radio"
                                      value="Continuing Student"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="channel-student"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Continuing Student
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>

                          <div className="col-span-full">
                            <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                              <fieldset>
                                <legend className="block text font-medium text-gray-700">
                                  New or Continuing Student?
                                </legend>
                                <div className="mt-3 space-y-2">
                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="status-new"
                                      name="enrollment_status"
                                      type="radio"
                                      value="new"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="status-new"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      New
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-x-3">
                                    <input
                                      id="status-contd"
                                      name="enrollment_status"
                                      type="radio"
                                      value="continuing"
                                      onChange={handleInputChange}
                                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                                      required
                                    ></input>
                                    <label
                                      htmlFor="status-contd"
                                      className="block text-sm font-medium leading-6 text-gray-700"
                                    >
                                      Continuing
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <button
                            type="button"
                            className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                            // disabled={registerBtnState}
                            //   disabled={true}
                            onClick={handleConfirmRegistration}
                          >
                            Register
                          </button>
                        </div>
                        {/* <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                        <svg
                          className="mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        We adhere to the highest standards of data security in
                        all processing and storage of your information.
                      </p> */}
                        {/* <div className="justify-between gap-y-4">
                <p className="mb-6 mt-6 flex justify-left text-sm font-medium">
                  Already Registered ?
                </p>
                <Link
                  to="/enroll"
                  className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Enroll Here
                </Link>
              </div> */}
                      </form>
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
