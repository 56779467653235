import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";
// import { loadItem, saveItem } from "../../utils/helpers/localStorage";

// export const fetchCourses = createAsyncThunk(
//   "/registrations/fetchCourses",
//   async () => {
//     // const courses = loadItem("courses");
//     // if (courses && courses.length !== 0) {
//     //   // console.log("courses", courses)
//     //   return courses;
//     // }else{
//       const { data: response } = await Apis.getCourses();
//       // console.log("APi data", response.msg);
//       if (response.data) {
//         return response.data;
//       } else {
//         return [];
//       }
//     // }
//   }
// );

// export const fetchCourse = createAsyncThunk(
//   "/courses/fetchCourse",
//   async (param) => {
//     const { data: response } = await Apis.getCourse(param);
//     // console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

// export const addCourseAsync = createAsyncThunk(
//   "courses/addCourse",
//   async (course) => {
//     try {
//       const { data: response } = await Apis.addCourse(course);
//       //   console.log("res success", response);
//       if (!response.success) {
//         return { data: null, msg: response.msg, status: 400 };
//       }
//       return { data: response.data, msg: response.msg, status: 200 };
//     } catch (error) {
//       const { data: response } = error.response;
//       const { status } = error;
//       console.log("Error:", status);
//       return { data: null, msg: response.msg, status: status };
//     }
//   }
// );

// export const selectCourseById = (state, courseId) => {
//   return state.courses.coursesList.find((course) => course.id === courseId);
// }

// Thunk for fetching all courses
export const fetchCourses = createAsyncThunk(
  "courses/fetchAll",
  async ({ page, pageSize, search }, { getState, rejectWithValue }) => {
    try {
      // check stored cache
      const { courses } = getState();
      const cachedPage = courses.pages[`${page}-${search}`];
      if (cachedPage) {
        return {
          data: cachedPage,
          total: courses.total,
          page,
          search,
        };
      }
      const {
        data: {
          data: { courses: data, total },
        },
      } = await Apis.getCourses({ params: { page, pageSize, search } });
      return { data, total, page, search };
    } catch (error) {
      // Reject with the error message
      console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      return rejectWithValue(msg || "Failed to fetch courses");
    }
  }
);

// Thunk for fetching a single course by ID
export const fetchCourse = createAsyncThunk(
  "courses/fetchCourse",
  async (param, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.getCourse(param);
      return { data, msg };
    } catch (error) {
      // console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to fetch course with ID: ${param}`);
    }
  }
);

// Thunk for creating a new course
export const createCourse = createAsyncThunk(
  "courses/create",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.addCourse(payload);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to add course. Try again later`);
    }
  }
);

// Thunk for updating an existing course
export const updateCourse = createAsyncThunk(
  "courses/update",
  async (courseData, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.updateCourse(courseData);
      return { data, msg, success };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Updating course failed. Try again later`);
    }
  }
);

// Thunk for deleting a course
export const deleteCourse = createAsyncThunk(
  "courses/delete",
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.deleteCourse(params);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("DeleteCourse;Error", msg);
      return rejectWithValue(
        msg || `Failed to delete course with ID: ${params.id}`
      );
    }
  }
);

const initialState = {
  allIds: [],
  byId: {},
  status: "idle",
  error: null,
  pageSize: 5,
  total: 0,
  pages: {}, //cached pages
  search: "",
  enrollCourse: {},
};

const courseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    // addCourse: (state, action) => {
    //   const courses = state.coursesList;
    //   const newCourses = [...courses, action.payload];
    //   return newCourses;
    // },
    setEnrollCourse: (state, action) => {
      return { ...state, enrollCourse: action.payload };
    },
    // getCourseById: (state, action) => {
    //   return state.coursesList.find((course) => course.id === action.payload);
    // },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    selectCoursesById: (state) => state.byId,
    selectAllCourseIds: (state) => state.allIds,
    selectCourseStatus: (state) => state.status,
    selectCourseError: (state) => state.error,
    selectTotal: (state) => state.total,
    selectPageSize: (state) => state.pageSize,
    selectSearchTerm: (state) => state.search,

    // selectCourseStatus: (state) => state.status,
    // selectCourseError: (state) => state.error,
    // selectCourses: (state) => state.coursesList,
    // selectCourseById: (state, course_id) =>
    //   state.coursesList.find((course) => course.id == course_id),
  },

  extraReducers(builder) {
    builder
      // .addCase(addCourseAsync.fulfilled, (state, action) => {
      //   const { data, msg, status } = action.payload;
      //   state.error = msg;
      //   if (status !== 200) {
      //     state.status = "failed";
      //   } else {
      //     state.status = "success";
      //     state.coursesList.push(data);
      //   }
      // })
      // .addCase(addCourseAsync.pending, (state, action) => {
      //   state.status = "pending";
      //   // state.coursesList.push(action.payload);
      // })
      // .addCase(addCourseAsync.rejected, (state, action) => {
      //   state.status = "failed";
      // })
      // .addCase(fetchCourse.fulfilled, (state, action) => {
      //   state.status = "success";
      //   state.courseItem = action.payload;
      // })
      // .addCase(fetchCourse.pending, (state, action) => {
      //   state.status = "pending";
      // })
      // .addCase(fetchCourse.rejected, (state, action) => {
      //   state.status = "failed";
      // })
      // .addCase(fetchCourses.fulfilled, (state, action) => {
      //   // console.log("action.payload", action.payload);
      //   state.status = "success";
      //   state.coursesList = action.payload;
      //   // saveItem("courses", action.payload);
      // })
      // .addCase(fetchCourses.pending, (state, action) => {
      //   state.status = "pending";
      // })
      // .addCase(fetchCourses.rejected, (state, action) => {
      //   state.status = "failed";
      // })
      // Create a new course
      .addCase(createCourse.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.byId[data.id] = data;
        state.allIds.push(data.id);
        state.total += 1;
      })
      // Update an existing course
      .addCase(updateCourse.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (state.byId[data.id]) {
          state.byId[data.id] = data;
        }
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
        const { data, total, page, search } = action.payload;
        state.total = total;
        if (Array.isArray(data)) {
          state.byId = data.reduce((acc, cls) => {
            acc[cls.id] = cls;
            return acc;
          }, {});
          state.allIds = data.map((cls) => cls.id);
          state.pages[`${page}-${search}`] = data; // Store courses search results
        } else {
          state.byId[data.id] = data;
          if (!state.allIds.includes(data.id)) state.allIds.push(data.id);
        }
      })
      // Delete a course
      .addCase(deleteCourse.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (data) {
          state.status = "success";
          delete state.byId[data];
          state.allIds = state.allIds.filter((id) => id !== data);
          state.total -= 1;
        } else {
          // console.log("in here!!")
          state.status = "failed";
        }
      })
      .addMatcher(
        isPending(
          fetchCourses,
          fetchCourse,
          createCourse,
          updateCourse,
          deleteCourse
        ),
        (action, state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(
          fetchCourses,
          fetchCourse,
          createCourse,
          updateCourse,
          deleteCourse
        ),
        (action, state) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

// export const { addCourse, setEnrollCourse, getCourseById } =
//   courseSlice.actions;

export const {
  selectCoursesById,
  selectAllCourseIds,
  selectCourseStatus,
  selectCourseError,
  selectTotal,
  selectPageSize,
  selectSearchTerm,
} = courseSlice.selectors;

export const { setEnrollCourse, setCurrentPage, setSearchTerm, setPageSize } =
  courseSlice.actions;

export const selectCourseById = (courseId) =>
  createSelector([selectCoursesById], (byId) => byId[courseId]);

export const selectCourses = createSelector(
  [selectCoursesById, selectAllCourseIds],
  (byId, allIds) => allIds.map((id) => byId[id])
);
export default courseSlice.reducer;
