import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const {
        data: { msg, user, success },
      } = await Apis.resetPassword(data); 
      return { user, msg, success };
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      console.log("Error:", msg);
      return rejectWithValue(
        msg || "Request Failed. Something went wrong. Please try again later."
      );
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, { rejectWithValue }) => {
    try {
      const {
        data: { msg, success },
      } = await Apis.forgotPassword(data);
      
      return { msg, success };
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      console.log("Error:", msg);
      return rejectWithValue(
        msg || "Request Failed. Something went wrong. Please try again later."
      );
    }
  }
);

export const verifyResetPassword = createAsyncThunk(
  "auth/verifyResetPassword",
  async (token, { rejectWithValue }) => {
    try {
      const {
        data: { msg },
      } = await Apis.verifyResetPassword(token);
      
      return { msg };
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      console.log("Error:", msg);
      return rejectWithValue(
        msg || "Request Failed. Something went wrong. Please try again later."
      );
    }
  }
);

const initialState = {
  loggedIn: false,
  state: "idle",
  authType: "",
  error: "",
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      switch (action.payload) {
        case true:
          return { ...state, loggedIn: true };
        case false:
          return { ...state, loggedIn: false };
        default:
          return state;
      }
    },
    setAuthType: (state, action) => {
      return { ...state, authType: action.payload };
    },
  },
  selectors: {
    selectAuth: (state) => state.loggedIn,
    selectAuthType: (state) => state.authType,
    selectAuthState: (state) => state.state,
    selectAuthError: (state) => state.error,
    selectUser: (state) => state.user,
  },
  extraReducers(builder) {
    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { user, msg } = action.payload;
        state.user = user;
        state.state = "success";
        state.error = msg;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        const { msg } = action.payload;
        state.state = "success";
        state.error = msg;
      })
      .addCase(verifyResetPassword.fulfilled, (state, action) => {
        const { msg } = action.payload;
        state.state = "success";
        state.error = msg;
      })
      .addMatcher(isPending(resetPassword), (state) => {
        state.state = "pending";
      })
      .addMatcher(isRejected(resetPassword), (state, action) => {
        state.state = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  selectAuth,
  selectAuthType,
  selectAuthState,
  selectAuthError,
  selectUser,
} = authSlice.selectors;

export default authSlice.reducer;

export const { setLogin, setAuthType } = authSlice.actions;
