import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Registrations } from "./registrations";
import {
  fetchMpesaClassicRequests,
  fetchPaymentRequests,
  fetchSTKRequests,
  fetchTransactionQueryRequests,
  fetchTransactions,
  selectMpesaClassicRequests,
  selectPaymentRequests,
  selectPaymentStatus,
  selectSTKRequests,
  selectTransactionQueries,
  selectTransactions,
} from "../../features/payments/paymentSlice";
import {
  fetchEnrollments,
  selectEnrollments,
  selectEnrollStatus,
} from "../../features/enrollment/enrollSlice";
import {
  fetchRegistrations,
  selectRegistrations,
  selectRegistrationState,
} from "../../features/registrations/registrationSlice";

export const Admin = () => {
  // const paymentRequestStatus = useSelector(selectPaymentStatus);
  // const enrollmentStatus = useSelector(selectEnrollStatus);
  // const registrationStatus = useSelector(selectRegistrationState);

  // const registrations = useSelector(selectRegistrations);
  // const enrollments = useSelector(selectEnrollments);
  // const paymentRequests = useSelector(selectPaymentRequests);
  // const transactions = useSelector(selectTransactions);
  // const transactionQueryRequests = useSelector(selectTransactionQueries);
  // const mpesaClassicRequests = useSelector(selectMpesaClassicRequests);
  // const STKRequests = useSelector(selectSTKRequests);

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (paymentRequestStatus === "idle" && paymentRequests.length === 0) {
  //     dispatch(fetchPaymentRequests());
  //   }
  //   if (paymentRequestStatus === "idle" && STKRequests.length === 0) {
  //     dispatch(fetchSTKRequests());
  //   }
  //   if (paymentRequestStatus === "idle" && transactions.length === 0) {
  //     dispatch(fetchTransactions());
  //   }
  //   if (paymentRequestStatus === "idle" && mpesaClassicRequests.length === 0) {
  //     dispatch(fetchMpesaClassicRequests());
  //   }
  //   if (
  //     paymentRequestStatus === "idle" &&
  //     transactionQueryRequests.length === 0
  //   ) {
  //     dispatch(fetchTransactionQueryRequests());
  //   }
  //   if (enrollmentStatus === "idle" && enrollments.length === 0) {
  //     dispatch(fetchEnrollments());
  //   }
  //   if (registrationStatus === "idle" && registrations.length) {
  //     dispatch(fetchRegistrations());
  //   }
  // }, [
  //   STKRequests.length,
  //   dispatch,
  //   enrollmentStatus,
  //   enrollments.length,
  //   mpesaClassicRequests.length,
  //   paymentRequestStatus,
  //   paymentRequests.length,
  //   registrationStatus,
  //   registrations.length,
  //   transactionQueryRequests.length,
  //   transactions.length,
  // ]);
  return (
    <>
      <Registrations />
    </>
  );
};
