import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";
// Thunk for fetching all tutors
export const fetchTutors = createAsyncThunk(
  "tutors/fetchTutors",
  async (
    { page, pageSize, search, tutorId },
    { getState, rejectWithValue }
  ) => {
    try {
      // check stored cache
      const { tutors } = getState();
      const cachedPage = tutors.pages[`${page}-${search}`];
      if (cachedPage) {
        return {
          data: cachedPage,
          total: tutors.total,
          page,
          search,
        };
      }
      const {
        data: {
          data: { tutors: data, total },
        },
      } = await Apis.getTutors({ params: { tutorId, page, pageSize, search } });
      return { data, total, page, search };
    } catch (error) {
      // Reject with the error message
      console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      return rejectWithValue(msg || "Failed to fetch tutors");
    }
  }
);

// Thunk for creating a new tutor
export const createTutor = createAsyncThunk(
  "tutors/create",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.addTutor(payload);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to add tutor. Try again later`);
    }
  }
);

// Thunk for updating an existing tutor
export const updateTutor = createAsyncThunk(
  "tutors/update",
  async (tutorData, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.updateTutor(tutorData);
      return { data, msg, success };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Updating tutor failed. Try again later`);
    }
  }
);

// Thunk for deleting a tutor
export const deleteTutor = createAsyncThunk(
  "tutors/delete",
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.deleteTutor(params);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("DeleteTutor;Error", msg);
      return rejectWithValue(
        msg || `Failed to delete tutor with ID: ${params.id}`
      );
    }
  }
);
// export const fetchTutors = createAsyncThunk(
//   "/registrations/fetchTutors",
//   async () => {
//     const { data: response } = await Apis.getTutors();
//     // console.log("data", response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

// export const fetchTutor = createAsyncThunk(
//   "/tutors/fetchTutor",
//   async (params) => {
//     // console.log("params", params)
//     const { data: response } = await Apis.getTutors(params);
//     // console.log("Tutor response", response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return {};
//     }
//   }
// );

// export const addTutorAsync = createAsyncThunk(
//   "tutors/addTutor",
//   async (tutor) => {
//    try {
//      const { status, data } = await Apis.addTutor(tutor);
//      //   console.log("res success", response);
//      if (status !== 201) {
//        return { data: null, msg: data.msg, status: status };
//      }

//      return { data: data.data, msg: data.msg, status: 200 };
//    } catch (error) {
//     //  console.log("Error", error);
//      const { data: response } = error.response;
//      const { status } = error;
//      return { data: null, msg: response.msg, status: status };
//    }
//   }
// );

// export const selectTutorById = (state, tutorId) => {
//   return state.tutors.tutors.find((tutor) => tutor.id === tutorId);
// }

// const initialState = {
//   status: "idle",
//   tutors: [],
//   error: null,
//   tutor: {},
// };

const initialState = {
  allIds: [],
  byId: {},
  status: "idle",
  error: null,
  pageSize: 5,
  total: 0,
  pages: {}, //cached pages
  search: "",
};
const tutorSlice = createSlice({
  name: "tutors",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    // selectTutorsStatus: (state) => state.status,
    // selectTutorsError: (state) => state.error,
    // selectTutors: (state) => state.tutors,
    // selectTutor: (state) => state.tutor,
    // selectTutorById: (state, tutorId) =>
    //   state.tutors.find((tutor) => tutor.id === tutorId),

    selectTutorsById: (state) => state.byId,
    selectAllTutorIds: (state) => state.allIds,
    selectTutorsStatus: (state) => state.status,
    selectTutorsError: (state) => state.error,
    selectTotal: (state) => state.total,
    selectPageSize: (state) => state.pageSize,
    selectSearchTerm: (state) => state.search,
  },
  extraReducers(builder) {
    builder
      .addCase(createTutor.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.byId[data.id] = data;
        state.allIds.push(data.id);
        state.total += 1;
      })
      // Update an existing tutor
      .addCase(updateTutor.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        state.status = "success";
        if (state.byId[data.id]) {
          state.byId[data.id] = data;
        }
      })
      .addCase(fetchTutors.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
        const { data, total, page, search } = action.payload;
        state.total = total;
        if (Array.isArray(data)) {
          state.byId = data.reduce((acc, cls) => {
            acc[cls.id] = cls;
            return acc;
          }, {});
          state.allIds = data.map((cls) => cls.id);
          state.pages[`${page}-${search}`] = data; // Store tutors search results
        } else {
          state.byId[data.id] = data;
          if (!state.allIds.includes(data.id)) state.allIds.push(data.id);
        }
      })
      // Delete a tutor
      .addCase(deleteTutor.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (data) {
          state.status = "success";
          delete state.byId[data];
          state.allIds = state.allIds.filter((id) => id !== data);
          state.total -= 1;
        } else {
          // console.log("in here!!")
          state.status = "failed";
        }
      })
      .addMatcher(
        isPending(fetchTutors, createTutor, updateTutor, deleteTutor),
        (action, state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(fetchTutors, createTutor, updateTutor, deleteTutor),
        (action, state) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );

    // .addCase(addTutorAsync.fulfilled, (state, action) => {
    //   const { data, msg, status } = action.payload;
    //   state.error = msg;
    //   if (status !== 200) {
    //     state.status = "failed";
    //   } else {
    //     state.status = "success";
    //     state.tutors.push(data);
    //   }
    // })
    // .addCase(addTutorAsync.pending, (state, action) => {
    //   state.status = "pending";
    //   // state.tutors.push(action.payload);
    // })
    // .addCase(addTutorAsync.rejected, (state, action) => {
    //   state.status = "failed";
    // })
    // .addCase(fetchTutor.fulfilled, (state, action) => {
    //   state.status = "success";
    //   state.tutor = action.payload;
    // })
    // .addCase(fetchTutor.pending, (state, action) => {
    //   state.status = "pending";
    // })
    // .addCase(fetchTutor.rejected, (state, action) => {
    //   state.status = "failed";
    // })
    // .addCase(fetchTutors.fulfilled, (state, action) => {
    //   // console.log("action.payload", action.payload);
    //   state.status = "success";
    //   state.tutors = action.payload;
    // })
    // .addCase(fetchTutors.pending, (state, action) => {
    //   state.status = "pending";
    // })
    // .addCase(fetchTutors.rejected, (state, action) => {
    //   state.status = "failed";
    // });
  },
});

// export const {
//   selectTutorsStatus,
//   selectTutorsError,
//   selectTutor,
// } = tutorSlice.selectors;

export const {
  selectTutorsById,
  selectAllTutorIds,
  selectTutorsStatus,
  selectTutorsError,
  selectTotal,
  selectPageSize,
  selectSearchTerm,
} = tutorSlice.selectors;

export const { setCurrentPage, setSearchTerm, setPageSize } =
  tutorSlice.actions;

export const selectTutorById = (tutorId) =>
  createSelector([selectTutorsById], (byId) => byId[tutorId]);

export const selectTutors = createSelector(
  [selectTutorsById, selectAllTutorIds],
  (byId, allIds) => allIds.map((id) => byId[id])
);

export default tutorSlice.reducer;
