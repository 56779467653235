import React, { useEffect, useState } from "react";
// import {
//   selectAllStudents,
//   getStudent,
//   fetchRegistrations,
//   selectRegistrationState,
//   selectStudentById,
// } from "../../features/registrations/registrationSlice";
import {
  fetchRegistrations,
  setCurrentPage,
  selectRegistrations,
  selectCurrentPage,
  selectPageSize,
  selectTotalCount,
  selectRegistrationState,
  selectSearchTerm,
  selectErrorMsg,
  setSearchTerm,
  selectTotalShowing,
  updateRegistration,
} from "../../features/registrations/registrationSlice"; // Import the Redux slice

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserDetails } from "../../utils/helpers/authHelpers";
import ExportEnrollmentsCSV from "./ExportCSV";
import Alert from "../../components/common/alert";
import { RegisterStudent } from "./registerStudent";
// import { fetchEnrollments } from "../../features/enrollment/enrollSlice";

// Utility for debouncing search input
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const ConfirmModal = ({show, handleClose, student, action}) => {
  const message = useSelector(selectErrorMsg);
  const status = useSelector(selectRegistrationState)
  const [alert, setAlert] = useState({ show: false, msg: message, type: status });
   const dispatch = useDispatch();

    const handleArchive = async () => {
      try {
        await dispatch(
          updateRegistration({ id: student.id, status: student.status })
        ).unwrap();
        setAlert({
          show: true,
          msg: "Student archived successfully!",
          type: "success",
        });
      } catch (error) {
        setAlert({
          show: true,
          msg: "Student archiving failed. Try again later!",
          type: "failed",
        });
      }
    };

    useEffect(() => {
      
      if (alert.show) {
        setTimeout(() => {
          setAlert({ show: false });
        }, 2500);
      }
    }, [alert.show]);


   return (
     <>
       {show && (
         <div
           className=" relative z-10"
           aria-labelledby="modal-title"
           role="dialog"
           aria-modal="true"
         >
           <div
             className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
             aria-hidden="true"
           ></div>

           <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
             <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
               <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                 <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                   <div className="sm:flex sm:items-center justify-end">
                     {/* <div className="sm:flex-auto">
                    <h1 className="text-lg font-semibold text-gray-900">
                      Are you sure you want to delete {className}?
                    </h1>
                  </div> */}
                     <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                       <button
                         type="button"
                         className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                         onClick={handleClose}
                       >
                         <svg
                           xmlns="http://www.w3.org/2000/svg"
                           fill="none"
                           viewBox="0 0 24 24"
                           strokeWidth="1.5"
                           stroke="currentColor"
                           className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                         >
                           <path
                             strokeLinecap="round"
                             strokeLinejoin="round"
                             d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                           />
                         </svg>
                       </button>
                     </div>
                   </div>
                   {/* <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr> */}
                   {alert.show && (
                     <Alert
                       show={alert.show}
                       content={alert.msg}
                       type={alert.type}
                     ></Alert>
                   )}
                   <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                     <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                       <div className="sm:flex-auto">
                         <h1 className="text-lg text-center font-normal text-gray-900">
                           Are you sure you want to update {student.name} to{" "}
                           <b>{student.status}</b>?
                         </h1>
                       </div>
                       <div className="mt-3 flex justify-between">
                         <button
                           onClick={handleClose}
                           className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                         >
                           Cancel
                         </button>
                         <button
                           type="button"
                           className="mt-3 rounded-md bg-emerald-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                           onClick={() => handleArchive()}
                         >
                           Yes, update
                         </button>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       )}
     </>
   );
}

export const Registrations = () => {
  const currentPage = useSelector(selectCurrentPage);
  const pageSize = useSelector(selectPageSize);
  const totalCount = useSelector(selectTotalCount);
  const totalShowing = useSelector(selectTotalShowing);
  const status = useSelector(selectRegistrationState);
  const searchTerm = useSelector(selectSearchTerm);
  const [localSearchParam, setLocalSearchParam] = useState(searchTerm);
  const [showExportModal, setShowExportModal] = useState(false);
  const [student, setStudent] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [registerNew, setRegisterNew] = useState(false);
  const total = searchTerm ? totalShowing : totalCount;

  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserDetails();

  // Calculate total number of pages
  const totalPages = Math.ceil(total / pageSize);

  // Calculate "from" and "to" values for the current page
  const from = (currentPage - 1) * pageSize + 1;
  const to = Math.min(currentPage * pageSize, total);

  // const registrations = useSelector((state) =>
  //   selectRegistrations(state, currentPage)
  // );
  const registrations = useSelector(selectRegistrations);
  // console.log("regs", registrations[0]?.full_name);

  const handleNextPage = () => {
    if (currentPage * pageSize < total) {
      dispatch(setCurrentPage(currentPage + 1));
    }
  };

  const handlePrevPage = () => {
    // console.log("Prev currentPage", currentPage);
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };
  // Handle search with debouncing
  const handleSearchChange = (e) => {
    setLocalSearchParam(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = debounce((searchTerm) => {
    dispatch(setSearchTerm(searchTerm));
    dispatch(setCurrentPage(1)); // Reset to page 1 when search changes
  }, 300); // 300ms debounce time

  const handleArchiveClick = ({id, name, status}) => {
    setStudent({id , name, status})
    setShowConfirm(true);
  };


  useEffect(() => {
    // if (status === "idle") {
    //   console.log("fetching");
    // }
    dispatch(
      fetchRegistrations({
        page: currentPage,
        pageSize: pageSize,
        search: searchTerm,
      })
    );
    // setDataToDisplay(registrations);
  }, [currentPage, dispatch, pageSize, searchTerm]);


  return (
    <>
      {showExportModal && (
        <ExportEnrollmentsCSV
          show={showExportModal}
          handleClose={() => setShowExportModal(false)}
        />
      )}
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          student={student}
          handleClose={() => setShowConfirm(false)}
        />
      )}
      {registerNew && (
        <RegisterStudent
          show={registerNew}
          handleClose={() => setRegisterNew(false)}
        />
      )}
      <div className="px-4">
        {user.user_type === "admin" && (
          <div className="pb-5 sm:flex sm:items-center sm:justify-between">
            <div className="mt-3 flex flex-col sm:flex-row sm:ml-auto sm:mt-0 w-full sm:w-auto">
              {/* <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Import
              </button> */}
              <button
                type="button"
                className="inline-flex items-center justify-center w-full sm:w-auto rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => setShowExportModal(true)}
              >
                Export
              </button>
            </div>
          </div>
        )}

        <div className="px-4 sm:px-6 lg:px-8 py-6 sm:py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base sm:text-lg font-semibold text-gray-900">
                Student Registrations
              </h1>
              <p className="mt-2 text-sm sm:text-base text-gray-700">
                A list of all students including their ID, phone number, age,
                and current residence.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none w-full sm:w-auto">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search students..."
                value={localSearchParam}
                onChange={handleSearchChange}
              />
            </div>
            {user.user_type === "admin" && (
               <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="rounded-md border border-transparent bg-sky-950  p-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-gray-500"
                onClick={() => setRegisterNew(true)}
              >
                Add New
              </button>
            </div>
            )}
           
          </div>
          {status === "pending" ? (
            <div className="flex items-center justify-center py-8">
              <div className="sm:flex-auto">
                <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                  Fetching Data
                </h5>
                <div className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-slate-600 rounded"></div>
                    <div className="space-y-3">
                      <div className="grid grid-cols-3 gap-4">
                        <div className="h-2 bg-slate-600 rounded col-span-2"></div>
                        <div className="h-2 bg-slate-600 rounded col-span-1"></div>
                      </div>
                      <div className="h-2 bg-slate-600 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : registrations?.length !== 0 ? (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Full Name
                        </th>
                        {/* <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Email
                        </th> */}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Phone Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Reg. Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          ID Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Current Residence
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Season
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Registration Time
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {registrations?.map((item, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.full_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            +{item.msisdn}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.registration_no}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.id_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.residence}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.season}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.status === "active" ? (
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {item.status}
                              </span>
                            ) : (
                              <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                {item.status}
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.created_at}
                          </td>
                          <td className="relative whitespace-nowrap space-x-2 py-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to="/student-details"
                              state={{ studentId: item.id }}
                              className="rounded-md border p-2 text-blue-600 hover:text-blue-900"
                            >
                              View
                            </Link>
                            <Link
                              to="/update-student"
                              state={{ id: item.id }}
                              className="rounded-md border text-sm font-medium text-blue-600 px-4 py-2 hover:bg-white"
                            >
                              Edit Student
                            </Link>
                            {user.user_type === "admin" &&
                              (item.status === "active" ? (
                                <button
                                  // to="/dashboard/edit-course"
                                  // state={{ id: classItem.id }}
                                  className="rounded-md text-sm font-medium text-white bg-amber-600 px-4 py-2 hover:bg-blue-800"
                                  onClick={() =>
                                    handleArchiveClick({
                                      id: item.id,
                                      name: item.full_name,
                                      status: "archived",
                                    })
                                  }
                                >
                                  Archive
                                </button>
                              ) : (
                                <button
                                  // to="/dashboard/edit-course"
                                  // state={{ id: classItem.id }}
                                  className="rounded-md text-sm font-medium text-white bg-amber-600 px-4 py-2 hover:bg-blue-800"
                                  onClick={() =>
                                    handleArchiveClick({
                                      id: item.id,
                                      name: item.full_name,
                                      status: "active",
                                    })
                                  }
                                >
                                  Unarchive
                                </button>
                              ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    // href="/"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    href="/"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{from}</span> to{" "}
                      <span className="font-medium"> {to}</span> of
                      <span className="font-medium"> {total}</span>
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        type="button"
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        // onClick={() => {
                        //   if (currentPage <= 1) {
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev - 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {/* Array(Math.round(registrations.length / itemsPerPage)) */}
                      {Array.from(Array(Math.round(total / pageSize)))
                        .map((e, i) => i + 1)
                        .map((val, idx) => {
                          return (
                            <button
                              type="button"
                              key={idx}
                              onClick={() => dispatch(setCurrentPage(val))}
                              aria-current="page"
                              className={
                                currentPage === val
                                  ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              }
                            >
                              {val}
                            </button>
                          );
                        })}
                      <button
                        type="button"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        // onClick={() => {
                        //   if (
                        //     currentPage >=
                        //     registrations.length / itemsPerPage
                        //   ) {
                        //     console.log("exceeded");
                        //     return;
                        //   } else {
                        //     setCurrentPage((prev) => prev + 1);
                        //   }
                        // }}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No data</p>
          )}
        </div>
      </div>
    </>
  );
};
