import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export const Sidebar = ({ authType }) => {
  const [activeTab, setActiveTab] = useState("dashboard");
  return (
    <>
      {/* <!-- Static sidebar for desktop --> */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 pt-4">
        <div className="flex h-16 shrink-0 items-center">
          <img
            className="h-12 w-auto"
            src="../assets/png/iyflogo-small.png"
            alt="Your Company"
          ></img>
        </div>
        {authType === "admin" ? (
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <nav>
                <ul className="-mx-2 space-y-1">
                  <li>
                    <NavLink
                      to=""
                      className={
                        activeTab === "dashboard"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("dashboard")}
                    >
                      <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="registrations"
                      // className="bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      className={
                        activeTab === "registrations"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("registrations")}
                    >
                      <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      Registrations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="enrollments"
                      // className="bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      className={
                        activeTab === "enrollments"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("enrollments")}
                    >
                      <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      Course Enrollments
                    </NavLink>
                  </li>

                  {/* <li className="mt-auto">
                    <NavLink
                      to="tutors"
                      className={
                        activeTab === "tutors"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("tutors")}
                    >
                      <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      Tutors
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="cashiers"
                      className={
                        activeTab === "cashiers"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("cashiers")}
                    >
                      <svg
                        className="h-6 w-6 shrink-0 text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      Cashiers
                    </NavLink>
                  </li> */}
                  <li className="mt-auto">
                    <NavLink
                      to="courses"
                      className={
                        activeTab === "courses"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("courses")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6 text-gray-500 group-hover:text-blue-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                        />
                      </svg>
                      {/* <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 0H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 6v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H2Zm11 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0h2a1 1 0 0 1 2 0v1Z"
                        />
                      </svg> */}
                      Courses
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="classes"
                      className={
                        activeTab === "classes"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("classes")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3"
                        />
                      </svg>
                      {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 text-gray-500 group-hover:text-blue-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z"
                          />
                        </svg> */}
                      {/* <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 0H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 6v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H2Zm11 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0h2a1 1 0 0 1 2 0v1Z"
                        />
                      </svg> */}
                      Classes & Sessions
                    </NavLink>
                  </li>
                  {/* <li>
                      <NavLink
                        to="sessions"
                        className={
                          activeTab === "sessions"
                            ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        }
                        onClick={() => setActiveTab("sessions")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6  text-gray-500 group-hover:text-blue-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122"
                          />
                        </svg>
                        Sessions & Attendance
                      </NavLink>
                    </li> */}
                  <li className="mt-auto">
                    <NavLink
                      to="payment-requests"
                      className={
                        activeTab === "payment-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("payment-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                      Payment Requests
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="transactions"
                      className={
                        activeTab === "transactions"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("transactions")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 13.5H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                        />
                      </svg>
                      {/* <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 0H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 6v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H2Zm11 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0h2a1 1 0 0 1 2 0v1Z"
                        />
                      </svg> */}
                      Transactions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="mpesa-express-requests"
                      className={
                        activeTab === "mpesa-express-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("mpesa-express-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      Mpesa Express Requests
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="mpesa-classic-requests"
                      className={
                        activeTab === "mpesa-classic-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("mpesa-classic-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      Mpesa Classic Requests (Paybill)
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="my-account"
                      className={
                        activeTab === "my-account"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("my-account")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                        />
                      </svg>
                      My Account
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="users"
                      className={
                        activeTab === "users"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("users")}
                    >
                      <svg
                        className="size-6  text-gray-500 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      System Users
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </ul>
          </nav>
        ) : authType === "cashier" ? (
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <nav>
                <ul className="-mx-2 space-y-1">
                  <li>
                    <NavLink
                      to=""
                      className={
                        activeTab === "dashboard"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("dashboard")}
                    >
                      <svg
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="registrations"
                      // className="bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      className={
                        activeTab === "registrations"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("registrations")}
                    >
                      <svg
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                        />
                      </svg>
                      Registrations
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="payment-requests"
                      className={
                        activeTab === "payment-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("payment-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                      Payment Requests
                    </NavLink>
                  </li>
                  <li className="mt-auto">
                    <NavLink
                      to="transactions"
                      className={
                        activeTab === "transactions"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("transactions")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 13.5H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                        />
                      </svg>
                      {/* <svg
                        className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 0H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 6v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6H2Zm11 3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0h2a1 1 0 0 1 2 0v1Z"
                        />
                      </svg> */}
                      Transactions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="mpesa-express-requests"
                      className={
                        activeTab === "mpesa-express-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("mpesa-express-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      Mpesa Express Requests
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="mpesa-classic-requests"
                      className={
                        activeTab === "mpesa-classic-requests"
                          ? "bg-gray-50 text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          : "text-gray-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      }
                      onClick={() => setActiveTab("mpesa-classic-requests")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      Mpesa Classic Requests (Paybill)
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </ul>
          </nav>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};
