import React, { useState } from "react";
import { Tutors } from "./tutors";
import AdminList from "../../features/admin/AdminList";
import { Cashiers } from "./cashiers";

export const Users = () => {
  const [activeTab, setActiveTab] = useState("tutors");
  return (
    <>
      <div className="sm:col-span-2 flex p-6">
        <div className="flex flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 pt-4">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <nav>
                <ul className="-mx-2 space-y-1">
                  <li>
                    <button
                      className={
                        activeTab === "tutors"
                          ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                          : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      }
                      onClick={() => setActiveTab("tutors")}
                    >
                      Tutors
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg> */}
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        activeTab === "cashiers"
                          ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                          : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      }
                      onClick={() => setActiveTab("cashiers")}
                    >
                      Cashiers
                    </button>
                  </li>
                  <li>
                    <button
                      className={
                        activeTab === "admins"
                          ? "bg-gray-200 rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                          : "rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800"
                      }
                      onClick={() => setActiveTab("admins")}
                    >
                      Admins
                    </button>
                  </li>
                </ul>
              </nav>
            </ul>
          </nav>
        </div>
        <div className="flex flex-col space-y-2">
          {activeTab === "admins" && <AdminList />}
          {activeTab === "tutors" && <Tutors />}
          {activeTab === "cashiers" && <Cashiers />}
        </div>
      </div>
    </>
  );
};
