import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchOne = createAsyncThunk(
  "/sessions/fetchOne",
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.fetchOneSession(params);
      if (!success) {
        return { data: null, msg: msg, status: 400 };
      }
      return { data: data, msg: msg, status: 200 };
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      // console.log("SessionError", msg, status);
      return rejectWithValue(
        msg || "Could not get session at this moment. Try again later"
      );
    }
  }
);

export const fetchSessions = createAsyncThunk(
  "/registrations/fetchSessions",
  async (
    { page, pageSize, search, sessionId, tutorId, classId, day },
    { getState, rejectWithValue }
  ) => {
    try {
      // check stored cache
      const state = getState();
      // console.log("state", registration);
      const cachedPage = state.sessions.pages[`${page}-${search}`];
      if (cachedPage) {
        return {
          sessions: cachedPage,
          total: state.sessions.totalShowing,
          page,
          search,
        };
      }

      const {
        data: { sessions, total },
      } = await Apis.getSessions({
        params: { page, pageSize, search, sessionId, tutorId, classId, day },
      });

      // console.log("res", sessions);
      return { sessions, total, page, search };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("Error:", status);
      return rejectWithValue(
        msg || "Could not fetch sessions at this moment. Try again later"
      );
    }
  }
);

// export const fetchSession = createAsyncThunk(
//   "/sessions/fetchSession",
//   async (param) => {
//     const { data: response } = await Apis.getSession(param);
//     // console.log(response.data);
//     if (response.data) {
//       return response.data;
//     } else {
//       return [];
//     }
//   }
// );

export const addSession = createAsyncThunk(
  "sessions/addSession",
  async (sessions, { rejectWithValue }) => {
    // try {
    //   const { data: response } = await Apis.addSessions(sessions);
    //   // console.log("res success", response);
    //   if (!response.success) {
    //     return null;
    //   }
    //   return response.data;
    // } catch (error) {
    //   console.log("Error:", error);
    //   return null;
    // }

    try {
      const { data: response } = await Apis.addSessions(sessions);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("Error:", status);
      return rejectWithValue(
        msg || "Could not add session at this moment. Try again later"
      );
    }
  }
);

// const index = state.classes.findIndex(
//           (item) => item.id === action.payload.id
//         );
//         state.classes[index] = action.payload;
//       })
export const updateSession = createAsyncThunk(
  "sessions/updateSession",
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.updateSession(data);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: data, msg: response.msg, status: 200 };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("Error:", status);
      return rejectWithValue(
        msg || "Could not update session at this moment. Try again later"
      );
    }
  }
);
export const assignTutor = createAsyncThunk(
  "sessions/assignTutor",
  async (data, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.assignTutorSession(data);
      //   console.log("res success", response);
      if (!response.success) {
        return { data: null, msg: response.msg, status: 400 };
      }
      return { data: response.data, msg: response.msg, status: 200 };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("Error:", status);
      return rejectWithValue(
        msg || "Could not assign session at this moment. Try again later"
      );
    }
  }
);
export const deleteSession = createAsyncThunk(
  "sessions/deleteSession",
  async (param, { rejectWithValue }) => {
    try {
      const { data: response } = await Apis.deleteSession(param);
      //   console.log("res success", response);
      if (!response.success) {
        return { msg: response.msg, status: 400 };
      }
      return { deleted: response.deleted, msg: response.msg, status: 200 };
    } catch (error) {
      console.log("Error", error)
      const {
        data: { msg },
      } = error.response;
      
      return rejectWithValue(
        msg || "Could not delete session at this moment. Try again later"
      );
    }
  }
);

const initialState = {
  status: "idle",
  sessions: [],
  error: null,
  session: null,
  filtered: [],
  currentPage: 1,
  pageSize: 20,
  total: 0,
  pages: {}, //cached pages
  search: "",
};

const sessionSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    addSession: (state, action) => {
      const sessions = state.sessions;
      const newSessions = [...sessions, action.payload];
      return newSessions;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    selectSessionsStatus: (state) => state.status,
    selectSessionsError: (state) => state.error,
    // selectSessions: (state) => state.sessions,
    // selectSession: (state) => state.session,
    // selectSessionByClass: (state, class_id) =>
    //   state.sessions.find((session) => session.class_id === class_id),
    // selectSessionById: (state, session_id) =>
    //   state.sessions.find((session) => session.id === session_id),
    selectCurrentPage: (state) => state.currentPage,
    selectPageSize: (state) => state.pageSize,
    selectTotal: (state) => state.total,
    selectSearchTerm: (state) => state.search,
    selectFilteredSessions: (state) => state.filtered,
  },
  extraReducers(builder) {
    builder
      .addCase(addSession.fulfilled, (state, action) => {
        const { data, msg, status } = action.payload;
        state.error = msg;
        if (status !== 200) {
          state.status = "failed";
        } else {
          state.status = "success";
          state.sessions.push(data);
        }
      })
      .addCase(fetchOne.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.session = data;
      })
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.status = "success";
        const { sessions, total, search, page } = action.payload;
        if (Array.isArray(sessions)) {
          state.sessions = sessions;
          state.total = total;
        } else {
          state.session = sessions;
        }
        if (search && page) {
          state.pages[`${page}-${search}`] = sessions;
        }
      })
      .addCase(assignTutor.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        const sessionItem =
          state.sessions.find((session) => session.id === data.id) ||
          state.session;
        sessionItem.tutor = data.tutor;
        state.error = msg;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        const { deleted, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.sessions = state.sessions.filter((item) => item.id !== deleted);
      })
      .addCase(updateSession.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;

        const index = state.sessions.findIndex((item) => item.id === data.id);
        state.sessions[index] = data;
        // state.sessions = state.sessions.filter((item) => item.id !== deleted);
      })
      .addMatcher(
        isPending(
          fetchOne,
          fetchSessions,
          updateSession,
          assignTutor,
          deleteSession
        ),
        (action, state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(
          fetchOne,
          fetchSessions,
          updateSession,
          assignTutor,
          deleteSession
        ),
        (action, state) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const { setCurrentPage, setPageSize, setSearchTerm } =
  sessionSlice.actions;

export const {
  // selectSession,
  selectSessionByClass,
  selectSessionsStatus,
  selectSessionsError,
  selectCurrentPage,
  selectPageSize,
  selectTotal,
  selectSearchTerm,
  selectFilteredSessions,
} = sessionSlice.selectors;

const selectState = (state) => state.sessions;

export const selectSessions = createSelector(
  selectState,
  (state) => state.sessions
);
export const selectSessionById = createSelector(
  [selectSessions, (_, sessionId) => sessionId, selectState],
  (sessions, sessionId, state) =>
    sessions.find((session) => session.id === sessionId) || state.session
);

export default sessionSlice.reducer;
