export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem("authSettings"));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  } else {
    const authSettings = {
      auth_server: "kefis-store",
      client_id: "frontend",
    };
    localStorage.setItem("authSettings", JSON.stringify(authSettings));
    const oidcKey = `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
    localStorage.setItem(oidcKey, "{}");
    return oidcKey;
  }
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getOidToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.token;
  }
  return null;
};

export const setAuth = ({ token, user_type, user_id }) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.token = token;
    oidc.user_type = user_type;
    oidc.user_id = user_id;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
    window.dispatchEvent(new Event("token_change"));
  }
};
export const setUserDetails = (user_details) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.user_details = user_details;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const deleteToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.token = null;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
    window.dispatchEvent(new Event("token_change"));
  }
};

export const getUserDetails = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.user_details;
  }
  return null;
};
