import { useDispatch, useSelector } from "react-redux";
import {
  fetchTransactions,
  selectPaymentStatus,
  selectTransactions,
} from "../../features/payments/paymentSlice";
import { getUserDetails } from "../../utils/helpers/authHelpers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const Transactions = () => {
  const transactions = useSelector(selectTransactions);
  const status = useSelector(selectPaymentStatus);
  const [itemsPerPage, setItemsPerPage] = useState(200);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  

  const user = getUserDetails();
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    const val = e.target.value;
    const data = transactions?.filter((transaction) => {
      return val.toLowerCase() === ""
        ? transaction
        : transaction.msisdn.includes(val) ||
            transaction.transaction_id?.toLowerCase().includes(val);
    });
    setDataToDisplay(data);
  };

  useEffect(() => {
    if (status === "idle" && transactions.length === 0) {
      dispatch(fetchTransactions());
    }
    const start = (currentPage - 1) * itemsPerPage;
    const end = currentPage * itemsPerPage;
    if (transactions.length !== 0) {
      setDataToDisplay(transactions.slice(start, end));
    }
  }, [currentPage, dispatch, itemsPerPage, status, transactions]);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {user.user_type === "admin" && (
          <div className="pb-5 sm:flex sm:items-center sm:justify-between">
            <div className="mt-3 flex sm:ml-auto sm:mt-0">
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Import
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Export
              </button>
            </div>
          </div>
        )}

        <div className="px-4 sm:px-6 lg:px-8 py-8 bg-white border border-gray-200 rounded-lg shadow-sm">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-lg font-semibold text-gray-900">
                M-Pesa Payment Transactions
              </h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <input
                type="search"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                placeholder="Search transaction..."
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          {dataToDisplay.length !== 0 ? (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 bg-none"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Transaction Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Narrative
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Phone No.
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 bg-none"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right text-sm font-semibold text-gray-900 bg-none"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {dataToDisplay.map((item, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.transaction_id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.amount}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.transaction_date}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.narrative}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.msisdn}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {item.status}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to="/transaction-details"
                              state={{ id: item.id }}
                              className="mx-2 rounded-md border p-2 text-blue-600 hover:text-blue-900"
                              onClick={() => {}}
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">1</span> to{" "}
                          <span className="font-medium">
                            {" "}
                            {itemsPerPage
                              ? itemsPerPage < transactions.length
                              : transactions.length}
                          </span>{" "}
                          of
                          <span className="font-medium">
                            {" "}
                            {transactions.length}
                          </span>{" "}
                          results
                        </p>
                      </div>
                      <div>
                        <nav
                          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                          aria-label="Pagination"
                        >
                          <button
                            type="button"
                            onClick={() => {
                              if (currentPage <= 1) {
                                return;
                              } else {
                                setCurrentPage((prev) => prev - 1);
                              }
                            }}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Previous</span>
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                          {Array.from(
                            Array(
                              Math.round(transactions.length / itemsPerPage)
                            )
                          )
                            .map((e, i) => i + 1)
                            .map((val, idx) => {
                              return (
                                <button
                                  type="button"
                                  key={idx}
                                  onClick={() => setCurrentPage(val)}
                                  aria-current="page"
                                  className={
                                    currentPage === val
                                      ? "relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                      : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                  }
                                >
                                  {val}
                                </button>
                              );
                            })}
                          <button
                            type="button"
                            onClick={() => {
                              if (
                                currentPage >=
                                transactions.length / itemsPerPage
                              ) {
                                return;
                              } else {
                                setCurrentPage((prev) => prev + 1);
                              }
                            }}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          >
                            <span className="sr-only">Next</span>
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>No Transactions</p>
          )}
        </div>
      </div>
    </>
  );
};
