import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  selectClassesStatus,
  selectClassesError,
  deleteClass,
} from "./slice";

import Alert from "../../components/common/alert";

const ConfirmDeleteModal = ({ show, handleClose, classId, className }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("false");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(selectClassesError);
  const status = useSelector(selectClassesStatus);

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
    await  dispatch(deleteClass({ params: { id: classId } })).unwrap();
    setAlertMsg({
      ...alertMsg,
      msg: error,
    });
    setShowAlert(true);
    } catch (err) {
      console.log("Error,", err);
    //   const { msg } = err;
      setAlertMsg({
        ...alertMsg,
        msg: error,
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }, []);

  return (
    <>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-6">
              <div className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-md sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex justify-end items-center mb-4">
                    {/* <div className="sm:flex-auto">
                    <h1 className="text-lg font-semibold text-gray-900">
                      Are you sure you want to delete {className}?
                    </h1>
                  </div> */}
                    <button
                      type="button"
                      className="p-2 rounded-full text-red-500 hover:bg-red-100"
                      onClick={handleClose}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                  {/* <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr> */}
                  {showAlert && (
                    <Alert
                      show={showAlert}
                      content={error}
                      type={status}
                    ></Alert>
                  )}
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full p-2 align-middle sm:px-6 lg:px-8">
                      <div className="sm:flex-auto">
                        <h1 className="text-lg text-center font-normal text-gray-900">
                          Are you sure you want to delete <b>{className}</b>?
                        </h1>
                      </div>
                      <div className="mt-3 flex justify-between">
                        <button
                          onClick={handleClose}
                          className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="mt-3 rounded-md bg-rose-500 p-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                          onClick={(e) => handleDelete(e)}
                        >
                          Yes, delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmDeleteModal;