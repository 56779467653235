import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchTransactions = createAsyncThunk(
  "/payments/fetchTransactions",
  async (params) => {
    const { data: response } = await Apis.getTransactions(params);
    // console.log("data", response.data);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchPaymentRequests = createAsyncThunk(
  "/payments/fetchPaymentRequests",
  async (params) => {
    const { data: response } = await Apis.getPaymentRequests(params);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchMpesaClassicRequests = createAsyncThunk(
  "/payments/MpesaClassicRequests",
  async (params) => {
    const { data: response } = await Apis.getMpesaClassicRequests(params);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);
export const fetchSTKRequests = createAsyncThunk(
  "/payments/STKRequests",
  async (params) => {
    const { data: response } = await Apis.getSTKPushRequests(params);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);

export const fetchTransactionQueryRequests = createAsyncThunk(
  "/payments/TransactionQueryRequests",
  async (params) => {
    const { data: response } = await Apis.getTransactionQueryRequests(params);
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  }
);



// export const selectPaymentById = (state, paymentId) => {
//   return state.payments.payments.find((payment) => payment.id === paymentId);
// }

const initialState = {
  status: "idle",
  paymentRequests: [],
  stkRequests: [],
  transactions: [],
  transactionQueries: [],
  mpesaClassicRequests: [],
  error: null
};

const paymentSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    addPayment: (state, action) => {
      const payments = state.payments;
      const newPayments = [...payments, action.payload];
      return newPayments;
    },
    getItemById: (state, action) => {
        switch (action.payload.item) {
            case "paymentRequest":
                 return state.paymentRequests.find(
                   (payment) => payment.id === action.payload.param
                 );
                // break;
            case "transaction":
                 return state.transactions.find(
                   (payment) => payment.id === action.payload.param
                 );
                // break;
            case "mpesaClassic":
                 return state.mpesaClassicRequests.find(
                   (payment) => payment.id === action.payload.param
                 );
                // break;
            case "stkRequest":
                 return state.stkRequests.find(
                   (payment) => payment.id === action.payload.param
                 );
                // break;
            case "transQuery":
                 return state.transactionQueries.find(
                   (payment) => payment.id === action.payload.param
                 );
            default:
                return {}
                // break;
        }
    },
  },
  selectors: {
    selectPaymentStatus: (state) => state.status,
    selectPaymentsError: (state) => state.error,
    selectPaymentRequests: (state) => state.paymentRequests,
    selectTransactionQueries: (state) => state.transactionQueries,
    selectTransactions: (state) => state.transactions,
    selectMpesaClassicRequests: (state) => state.mpesaClassicRequests,
    selectSTKRequests: (state) => state.stkRequests,
    // selectPaymentById: (state, id) =>
    //   state.payments.find((payment) => payment.id === id),
  },
  extraReducers(builder) {
    builder
      //   .addCase(addPaymentAsync.fulfilled, (state, action) => {
      //     const { data, msg, status } = action.payload;
      //     state.error = msg;
      //     if (status !== 200) {
      //       state.status = "failed";
      //     } else {
      //       state.status = "success";
      //       state.payments.push(data);
      //     }
      //   })
      //   .addCase(addPaymentAsync.pending, (state, action) => {
      //     state.status = "pending";
      //     // state.payments.push(action.payload);
      //   })
      //   .addCase(addPaymentAsync.rejected, (state, action) => {
      //     state.status = "failed";
      //   })
      .addCase(fetchPaymentRequests.fulfilled, (state, action) => {
        state.status = "success";
        state.paymentRequests = action.payload;
      })
      .addCase(fetchPaymentRequests.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchPaymentRequests.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        // console.log("Transactions", action.payload);
        state.status = "success";
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchMpesaClassicRequests.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload);
        state.status = "success";
        state.mpesaClassicRequests = action.payload;
      })
      .addCase(fetchMpesaClassicRequests.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchMpesaClassicRequests.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchSTKRequests.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload);
        state.status = "success";
        state.stkRequests = action.payload;
      })
      .addCase(fetchSTKRequests.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchSTKRequests.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(fetchTransactionQueryRequests.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload);
        state.status = "success";
        state.transactionQueries = action.payload;
      })
      .addCase(fetchTransactionQueryRequests.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchTransactionQueryRequests.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { addPayment, getPaymentById } = paymentSlice.actions;

export const {
  selectPaymentStatus,
  selectPaymentsError,
  selectPaymentRequests,
  selectTransactions,
  selectTransactionQueries,
  selectSTKRequests,
  selectMpesaClassicRequests,
} = paymentSlice.selectors;

export default paymentSlice.reducer;
