import React from "react";

const Alert = ({ content, type }) => {
  var classProps =
    "mb-4 p-3 text-lg text-gray-900 text-center font-normal bg-bg-sky-400 rounded-lg";
  if (type === "failed") {
    classProps =
      "mb-4 p-3 text-lg text-white text-center font-normal rounded-lg  bg-red-500";
  }else if(type === "success"){
    classProps =
      "mb-4 p-3 text-lg text-gray-900 text-center font-normal rounded-lg  bg-emerald-300";
  }
  return (
    <>
      <div
        className={classProps}
        role="alert"
      >
       {content}
      </div>
    </>
  );
};

export default Alert;
