import { Route, Routes } from "react-router-dom";
import { routes } from "../routes/routes";

export const Auth = () => {
  return (
    <>
      <Routes>
        {routes.map(
          ({ layout, pages }) =>
            layout === "auth" &&
            pages.map(({ path, element }) => (
              <Route exact path={path} element={element} />
            ))
        )}
      </Routes>
    </>
  );
}