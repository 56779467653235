import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  selectAuthError,
  selectAuthState,
} from "../../features/auth/authSlice";
import Alert from "../../components/common/alert";

export const ForgotPassword = () => {
  const [msisdn, setPhoneNumber] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    msg: "",
    ype: "",
  });

  const error = useSelector(selectAuthError);
  const status = useSelector(selectAuthState);

  const dispatch = useDispatch();


  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (msisdn) {
        await dispatch(forgotPassword({ msisdn }))
          .unwrap()
          .then(({ msg, success }) => {
            console.log(success, msg);
            if (success) {
              console.log("msg", msg);
              setAlert({
                ...alert,
                show: true,
                msg: `${msg}. A password reset link has been sent to your email.  Please check your email to continue.`,
              });
            } else {
              setAlert({
                ...alert,
                show: true,
                msg: `${msg}`,
              });
            }
          });
      } else {
        // console.log("No data supplied");
        setAlert({
          ...alert,
          show: true,
          // msg: "Invalid Phone number. Phone number is required",
          msg: error,
        });
      }
    } catch (err) {
      console.log("forgot password error", err);
      setAlert({
        ...alert,
        show: true,
        msg: err,
      });
    }
  };

  useEffect(() => {
    // if (alert.show) {
    //   setTimeout(() => {
    //     setAlert({...alert, show: false});
    //   }, 5000);
    // }
  }, []);

  return (
    <>
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
      >
        <div className="mx-auto max-w-lg">
          <div className="mx-auto max-w-lg">
            <div className="pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy - Login</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                ></img>
              </a>
            </div>
          </div>
          {alert.show && (
            <Alert show={alert.show} content={alert.msg} type={status}></Alert>
          )}
          <form className="mt-6 space-y-1" onSubmit={handleSubmit}>
            <p className="px-1.5 text-base">
              Enter your registered phone number below to reset your password.
            </p>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-full border border-gray-200 rounded-xl shadow-sm">
                <div className="p-3 bg-white">
                  <label
                    htmlFor="msisdn"
                    className="block text font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="msisdn"
                      id="msisdn"
                      autoComplete="Phone Number"
                      placeholder="0712345678"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      onChange={handlePhoneNumberChange}
                      required
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="mt-6 rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>

            {/* <div className="flex items-start mb-5">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required
              />
            </div>
            <label
              htmlFor="remember"
              className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember me
            </label>
          </div> */}
          </form>
        </div>
      </section>
    </>
  );
};
