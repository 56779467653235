import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClass, selectClassesError, selectClassesStatus } from "./slice";
import Alert from "../../components/common/alert";
import {
  selectCourses,
  selectCourseStatus,
} from "../courses/courseSlice";

const AddClassModal = ({ show, handleClose, courseId }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [classItem, setClassItem] = useState({});
  const [course, setCourse] = useState({});
  const [sessionInputs, setSessionInputs] = useState([
    {
      day: "",
      start_at: "",
      end_at: "",
    },
  ]);

  const dispatch = useDispatch();

  const status = useSelector(selectClassesStatus);
  const error = useSelector(selectClassesError);

  const courseStatus = useSelector(selectCourseStatus);
  const courses = useSelector(selectCourses);

  const addSessionInput = () => {
    setSessionInputs((arr) => {
      return [...arr, {}];
    });
  };
  const handleSessionInput = (id) => (e) => {
    const { name, value } = e.target;

    const sessionValues = sessionInputs.map((el, idx) =>
      idx === id ? { ...el, [name]: value } : el
    );
    // console.log("sessionInputs", sessionInputs);
    setClassItem({ ...classItem, sessions: sessionValues });
    setSessionInputs(sessionValues);
  };
  const handleCourseInput = (e) => {
    // setCourseId(e.target.value);
    const course = courses.find(
      (course) => course.id === parseInt(e.target.value)
    );
    // setClassItem({ ...classItem, course_id: course.id });
    setCourse(course);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClassItem({ ...classItem, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        createClass({ ...classItem, course_id: course.id })
      ).unwrap();
    } catch (err) {
      console.log("Error,", err);
      const { msg } = err;
      setAlertMsg({
        ...alertMsg,
        msg: msg,
      });
      setShowAlert(true);
    } finally {
      setAlertMsg({
        ...alertMsg,
        msg: "Class Added Successfully",
      });
      setShowAlert(true);
      e.target.reset();
    }
  };

  useEffect(() => {
    if (courseId) {
      const course = courses.find((course) => course.id === courseId);
      setCourse(course);
    }

    setTimeout(() => {
      if (showAlert) {
        setShowAlert(false);
      }
    }, 5000);
  }, [courseId, courses, dispatch, showAlert]);

  return (
    <>
      {show && (
        <div
          className=" relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-15 transition-opacity"
            aria-hidden="true"
          ></div>

          <div className="fixed inset-0 z-1 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-lg font-semibold text-gray-900">
                        Add Class
                      </h1>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="mt-2 justify-center py-2 text-sm font-semibold text-white ring-gray-300 sm:mt-0 sm:w-auto"
                        onClick={handleClose}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-6 rounded-md bg-red-500 hover:bg-red-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      {courseStatus === "pending" ? (
                        <div className="flex items-center justify-center py-8">
                          <div className="sm:flex-auto">
                            <h5 className="mb-2 text-center text-2xl font-bold tracking-tight text-gray-400 dark:text-white opacity-20">
                              Loading ...
                            </h5>
                            <div className="animate-pulse flex space-x-4">
                              <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-600 rounded"></div>
                                <div className="space-y-3">
                                  <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-slate-600 rounded col-span-2"></div>
                                    <div className="h-2 bg-slate-600 rounded col-span-1"></div>
                                  </div>
                                  <div className="h-2 bg-slate-600 rounded"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form className="mt-6" onSubmit={handleSubmit}>
                          <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                            <div className="flex justify-center  col-span-full">
                              {showAlert && (
                                <Alert
                                  show={showAlert}
                                  content={error}
                                  type={status}
                                ></Alert>
                              )}
                            </div>
                            {/* <legend className="block text font-medium text-gray-700">
                                        Class Info
                                      </legend> */}
                            {courseId ? (
                              <div className="col-span-full">
                                <label
                                  htmlFor="course_code"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Course
                                </label>
                                <div className="mt-1">
                                  <input
                                    disabled={true}
                                    name="course"
                                    id="course"
                                    placeholder={course.course_name}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    defaultValue={course.course_name}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="col-span-full">
                                <label
                                  htmlFor="select-course"
                                  className="block text font-medium text-gray-700"
                                >
                                  Select Course
                                </label>
                                <div className="mt-1">
                                  <select
                                    id="select-course"
                                    name="course"
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                    onChange={handleCourseInput}
                                    defaultValue="Select Course"
                                  >
                                    <option>Select Course</option>
                                    {courses.map((course, index) => (
                                      <option key={index} value={course.id}>
                                        {course.course_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                            <div className="col-span-full">
                              <label
                                htmlFor="course_code"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Class Name
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="class_name"
                                  id="class_name"
                                  placeholder="Class Name"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <fieldset>
                                <legend className="block text font-medium text-gray-700">
                                  Class Sessions
                                </legend>

                                {sessionInputs.map((item, index) => (
                                  <div className="mt-3 space-y-2" key={index}>
                                    <div className="flex items-center gap-x-3">
                                      <label
                                        htmlFor="session-day"
                                        className="block text-sm font-medium leading-6 text-gray-700"
                                      >
                                        Day
                                      </label>
                                      <select
                                        id="session-day"
                                        name="day"
                                        placeholder="Select Class"
                                        value={item.day || ""}
                                        onChange={(e) =>
                                          handleSessionInput(index)(e)
                                        }
                                        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-700 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                                      >
                                        <option>Select Day</option>
                                        <option key="sat" value="Saturday">
                                          Saturday
                                        </option>
                                        <option key="sun" value="Sunday">
                                          Sunday
                                        </option>
                                        <option key="chapel" value="Chapel">
                                          Sunday Chapel
                                        </option>
                                      </select>
                                    </div>
                                    <div className="flex items-end gap-x-3">
                                      <div className="flex items-center gap-x-3">
                                        <label
                                          htmlFor="session-start"
                                          className="block text-sm font-medium leading-6 text-gray-700"
                                        >
                                          Start At:
                                        </label>
                                        <input
                                          id="session-start"
                                          name="start_at"
                                          type="time"
                                          min="07:00"
                                          max="18:00"
                                          placeholder="Start Time"
                                          // value={item.start_at || ""}
                                          onChange={(e) =>
                                            handleSessionInput(index)(e)
                                          }
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        ></input>
                                      </div>
                                      <div className="flex items-center gap-x-3">
                                        <label
                                          htmlFor="session-end"
                                          className="block text-sm font-medium leading-6 text-gray-700"
                                        >
                                          End At:
                                        </label>
                                        <input
                                          id="session-end"
                                          name="end_at"
                                          type="time"
                                          min="07:00"
                                          max="18:00"
                                          // value={item.end_at || ""}
                                          placeholder="Session End Time"
                                          onChange={(e) =>
                                            handleSessionInput(index)(e)
                                          }
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <div className="flex items-end gap-x-3">
                                  <div className="mt-3 space-y-2">
                                    <div className="mt-3 space-y-2">
                                      <button
                                        type="button"
                                        onClick={() => addSessionInput()}
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
                                      >
                                        + Add Session
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                          <div className="mt-3 flex justify-between">
                            <button
                              type="button"
                              onClick={handleClose}
                              className="mt-3 rounded-md text-base text-blue-600 px-4 py-2 hover:bg-white"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="mt-3 rounded-md bg-blue-700 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 sm:ml-3 sm:w-auto"
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddClassModal;
