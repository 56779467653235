import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {  useLocation, useNavigate } from "react-router-dom";
import { loadItem } from "../../utils/helpers/localStorage";
import { verifyEnrollment } from "../../features/enrollment/enrollSlice";

export const ConfirmRegistration = () => {
  const [student, setStudent] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  // const newStudent = useSelector(selectStudent);
  const [enrollmentResponse, setEnrollmentResponse] = useState({});

  const handleCompleteEnrollment = async (e) => {
    try {
      // console.log("VerificationInfo::", verificationInfo);
      if (Object.keys(enrollmentResponse || {}).length !== 0) {
        await dispatch(verifyEnrollment(enrollmentResponse))
          .unwrap()
          .then((results) => {
            // console.log("Verification results", results);
            const { data, msg, status } = results;
            if (status !== 200) {
              navigate("/enrollment-successful", {
                state: { status: {} },
              });
            } else {
              navigate("/enrollment-successful", {
                state: { student_id: data.student_id },
              });
            }
          });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (Object.keys(state || {}).length !== 0) {
      setEnrollmentResponse(state);
    }

    const newStudent = loadItem("student");
    // console.log("newStudent::::", newStudent);
    if (newStudent) {
      setStudent(newStudent);
    } else {
      navigate("/");
    }
    //   setTimeout(() => {
    //     setShowAlert(false);
    //   }, 6000);

    //   setTimeout(() => {
    //     if (showAlert) {
    //       setShowAlert(false);
    //     }
    //     if (showError) {
    //       setShowError(false);
    //     }
    //     if (showLoading) {
    //       setShowLoading(false);
    //     }
    //   }, 5000);
  }, [navigate, state]);
  return (
    <>
      <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
        <div className="px-4 py-6 sm:px-6 lg:hidden">
          <div className="mx-auto flex max-w-lg">
            <a href="/">
              <span className="sr-only">IYF Free Weekend Academy</span>
              <img
                src="../assets/png/iyflogo-s7.png"
                alt=""
                className="h-12 w-auto"
              />
            </a>
          </div>
        </div>

        <h1 className="sr-only">
          IYF Free Academy - Registration Confirmation
        </h1>

        <section
          aria-labelledby="payment-heading"
          className="flex-auto overflow-y-auto px-4 pb-16 sm:pt-12 pt-0 sm:px-6 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg">
            <div className="hidden pb-4 pt-12 lg:flex">
              <a href="/">
                <span className="sr-only">IYF Free Academy</span>
                <img
                  src="../assets/png/iyflogo-s7.png"
                  alt=""
                  className="h-20 w-auto"
                />
              </a>
            </div>

            <div className="grid grid-cols-12 gap-x-4 gap-y-6">
              <div className="col-span-full">
                <div className="p-4 bg-white border border-gray-200 rounded-xl shadow-sm">
                  <div>
                    <h3 className="font-medium text-gray-900">
                      Thank you for registering. Below is the information you
                      submitted
                    </h3>
                    <dl className="mt-2 divide-y space-x-2 divide-gray-200 border-t border-gray-200">
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Name</dt>
                        <dd className="text-gray-900">{student.full_name}</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Date of Birth</dt>
                        <dd className="text-gray-900">{student.dob}</dd>
                      </div>
                      {student.id_number && (
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">ID Number</dt>
                          <dd className="text-gray-900">{student.id_number}</dd>
                        </div>
                      )}

                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Phone Number</dt>
                        <dd className="text-gray-900">{student.msisdn}</dd>
                      </div>
                      {student.guardian_name && (
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Name of Parent</dt>
                          <dd className="text-gray-900">
                            {student.guardian_name}
                          </dd>
                        </div>
                      )}
                      {student.guardian_id_no && (
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">
                            ID No. of Parent or Guardian
                          </dt>
                          <dd className="text-gray-900">
                            {student.guardian_id_no}
                          </dd>
                        </div>
                      )}

                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Current Residence</dt>
                        <dd className="text-gray-900">{student.residence}</dd>
                      </div>
                      {/* <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">Course</dt>
                        <dd className="text-gray-900">UI/UX</dd>
                      </div> */}
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">
                          How did you know about weekend academy?
                        </dt>
                        <dd className="text-gray-900">{student.channel}</dd>
                      </div>
                      <div className="flex justify-between py-3 text-sm font-medium">
                        <dt className="text-gray-500">
                          New or Continuing student?
                        </dt>
                        <dd className="text-gray-900">
                          {student.enrollment_status}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <button
                  className="flex justify-between rounded-md text-sm bg-blue-600 text-white px-4 py-2 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  type="button"
                  onClick={handleCompleteEnrollment}
                >
                  Complete
                </button>

                <div className="flex justify-between">
                  {/* <Link
                    to="/"
                    className="rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-600"
                  >
                    Register Another
                  </Link> */}
                  {/* <Link
                    to="/enroll"
                    // type="submit"
                    state={{ id: student.id }}
                    className="flex justify-between rounded-md text-sm bg-blue-600 text-white px-4 py-2 shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Proceed to Pay
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
