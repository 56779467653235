import {
  createSlice,
  createAsyncThunk,
  createSelector,
  isRejected,
  isPending,
} from "@reduxjs/toolkit";
import Apis from "../../utils/api/api";

export const fetchCashiers = createAsyncThunk(
  "cashiers/fetchCashiers",
  async (
    { page, pageSize, search, cashierId },
    { getState, rejectWithValue }
  ) => {
    try {
      // check stored cache
      console.log(cashierId);
      const { cashiers } = getState();
      const cachedPage = cashiers.pages[`${page}-${search}`];
      if (cachedPage) {
        return {
          data: cachedPage,
          total: cashiers.total,
          page,
          search,
        };
      }
      const {
        data: {
          data: { cashiers: data, total },
        },
      } = await Apis.getCashiers({
        params: { cashierId, page, pageSize, search },
      });
      return { data, total, page, search };
    } catch (error) {
      // Reject with the error message
      //  console.log("error", error);
      const {
        data: { msg },
      } = error.response;
      return rejectWithValue(msg || "Failed to fetch cashiers");
    }
  }
);

// Thunk for creating a new cashier
export const createCashier = createAsyncThunk(
  "cashiers/create",
  async (payload, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.addCashier(payload);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Failed to add cashier. Try again later`);
    }
  }
);

// Thunk for updating an existing cashier
export const updateCashier = createAsyncThunk(
  "cashiers/update",
  async (cashierData, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg, success },
      } = await Apis.updateCashier(cashierData);
      return { data, msg, success };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // Pass custom error payload to the rejected action
      return rejectWithValue(msg || `Updating cashier failed. Try again later`);
    }
  }
);

// Thunk for deleting a cashier
export const deleteCashier = createAsyncThunk(
  "cashiers/delete",
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data, msg },
      } = await Apis.deleteCashier(params);
      return { data, msg };
    } catch (error) {
      const {
        data: { msg },
      } = error.response;
      // console.log("DeleteCashier;Error", msg);
      return rejectWithValue(
        msg || `Failed to delete cashier with ID: ${params.id}`
      );
    }
  }
);

// export const selectCashierById = (state, cashierId) => {
//   return state.cashiers.cashiers.find((cashier) => cashier.id === cashierId);
// }

const initialState = {
  allIds: [],
  byId: {},
  status: "idle",
  error: null,
  pageSize: 5,
  total: 0,
  pages: {}, //cached pages
  search: "",
};

const cashierSlice = createSlice({
  name: "cashiers",
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  selectors: {
    selectCashiersById: (state) => state.byId,
    selectAllCashierIds: (state) => state.allIds,
    selectCashiersStatus: (state) => state.status,
    selectCashiersError: (state) => state.error,
    selectTotal: (state) => state.total,
    selectPageSize: (state) => state.pageSize,
    selectSearchTerm: (state) => state.search,
  },
  extraReducers(builder) {
    builder
      .addCase(createCashier.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.status = "success";
        state.error = msg;
        state.byId[data.id] = data;
        state.allIds.push(data.id);
        state.total += 1;
      })
      // Update an existing cashier
      .addCase(updateCashier.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        state.status = "success";
        if (state.byId[data.id]) {
          state.byId[data.id] = data;
        }
      })
      .addCase(fetchCashiers.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
        const { data, total, page, search } = action.payload;
        state.total = total;
        if (Array.isArray(data)) {
          state.byId = data.reduce((acc, cls) => {
            acc[cls.id] = cls;
            return acc;
          }, {});
          state.allIds = data.map((cls) => cls.id);
          state.pages[`${page}-${search}`] = data; // Store cashiers search results
        } else {
          state.byId[data.id] = data;
          if (!state.allIds.includes(data.id)) state.allIds.push(data.id);
        }
      })
      // Delete a cashier
      .addCase(deleteCashier.fulfilled, (state, action) => {
        const { data, msg } = action.payload;
        state.error = msg;
        if (data) {
          state.status = "success";
          delete state.byId[data];
          state.allIds = state.allIds.filter((id) => id !== data);
          state.total -= 1;
        } else {
          // console.log("in here!!")
          state.status = "failed";
        }
      })
      .addMatcher(
        isPending(
          fetchCashiers,
          createCashier,
          updateCashier,
          deleteCashier
        ),
        (action, state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        isRejected(
          fetchCashiers,
          createCashier,
          updateCashier,
          deleteCashier
        ),
        (action, state) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export const {
  selectCashiersById,
  selectAllCashierIds,
  selectCashiersStatus,
  selectCashiersError,
  selectTotal,
  selectPageSize,
  selectSearchTerm,
} = cashierSlice.selectors;

export const { setCurrentPage, setSearchTerm, setPageSize } =
  cashierSlice.actions;

export const selectCashierById = (cashierId) =>
  createSelector([selectCashiersById], (byId) => byId[cashierId]);

export const selectCashiers = createSelector(
  [selectCashiersById, selectAllCashierIds],
  (byId, allIds) => allIds.map((id) => byId[id])
);

export default cashierSlice.reducer;
